import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  gql,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
// import * as WebSocket from 'ws';
// import * as base64 from 'base-64';
import { useEffect, useState } from 'react';

import { Base64 as base64 } from 'js-base64';
export interface ApiResponseLiveToday {
  status: string;
  message: string;
}

export interface OrderDetails {
  PositionID: string;
  Position: string;
  Quantity: string;
  EntryAveragePrice: string;
  Entry: boolean;
  Direction: string;
  ExitAveragePrice: string | null;
  PnL: string | null;
  strategy_id?: string;
  OrderStatus: string | null;
}

export interface PositionDetails {
  PositionID: string;
  Position: string;
  Quantity: string;
  EntryAveragePrice: string | number;
  Entry: boolean;
  Direction: string;
  ExitAveragePrice: string | null;
  PnL: string | null;
  ExitOrderStatus: string | null;
  strategy_id?: string;
  OrderStatus: string | null;
}

export interface UserLiveTradeAPICollectionInterface {
  PositionDetails: PositionDetails[][];
}

export interface UserLiveTradeAPIInterface {
  response_code: number;
  error_code: string;
  error_message: string;
  request_id: string;
  time_taken: number;
  request_timestamp: string;
  response_message: string;
  response_data: {
    PositionDetails: PositionDetails[];
  };
}

export type MinCapResponseData = {
  [key: string]: string;
};

export interface SubscriptionDetailResponseData {
  strategy_id: string;
  lots: number;
  is_active: boolean;
  strategy_name: string;
}

export interface SubscriptionDetailInterface {
  Status: number;
  Response_data: SubscriptionDetailResponseData[];
}





// Define your AWS AppSync API information
const apiHeader = {
  host: process.env.REACT_APP_AWS_API_HEADER_HOST as string,
  'x-api-key': process.env.REACT_APP_APPSYNC_APIKEY as string,
};

// Payload should be an empty JSON object
const payload = {};

// Encode API header and payload to base64
const base64ApiHeader = base64.encode(JSON.stringify(apiHeader));
const base64Payload = base64.encode(JSON.stringify(payload));

// Construct the WebSocket URL
const url =
  process.env.REACT_APP_WEBSOCKET_URL as string;
const appsyncUrl = `${url}?header=${base64ApiHeader}&payload=${base64Payload}`;

// console.log('appsyncUrl', appsyncUrl);

// WebSocket connection
const ws = new WebSocket(appsyncUrl);

// Define a listener to handle incoming messages
// ws.on('message', (message: string) => {
//   console.log('Received:', message);
// });

// // Handle errors
// ws.on('error', (error: Error) => {
//   console.error('WebSocket error:', error);
// });

// // Handle connection close
// ws.on('close', (code: number, reason: string) => {
//   console.log(`WebSocket connection closed. Code: ${code}, Reason: ${reason}`);
// });

export const fetchAllTradingPositionByStrategyAndUserId = async (
  userId: string,
  strategy_id: string
) => {
  // Define the GraphQL query
  const GET_POSITION_IDS = gql`
    query MyQuery($strategyId: String!, $userId: String!) {
      getPositionIdsByStrategyId(StrategyID: $strategyId, UserId: $userId) {
        MoneyUserOrderDetails(userId: $userId) {
          PositionID
          Position
          TradingDate
          Entry
          Quantity
          ExitAveragePrice
          AveragePrice
          Lots
          ExitOrderStatus
          Direction
          OrderStatus
          MoneyDevPositionLTP {
            LTP
          }
        }
      }
    }
  `;

  // Create an Apollo client
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_APOLLO_CLIENT as string,
  });

  // Set the API key
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        'x-api-key': process.env.REACT_APP_APPSYNC_API_KEY as string,
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  let userLiveTradeDetails: UserLiveTradeAPIInterface = {
    response_data: {
      PositionDetails: [],
    },
    response_code: 0,
    error_code: '',
    error_message: '',
    request_id: '',
    time_taken: 0,
    request_timestamp: '',
    response_message: '',
  };

  try {
    // Execute the GraphQL query
    const { data } = await client.query({
      query: GET_POSITION_IDS,
      variables: {
        strategyId: strategy_id,
        userId: userId,
      },
    });

    let positionDetails: any[] = data.getPositionIdsByStrategyId;
    positionDetails.forEach((pos) => {
      if (pos.MoneyUserOrderDetails && pos.MoneyUserOrderDetails.Position) {
        let livePositionDetails: PositionDetails = {
          Position: pos.MoneyUserOrderDetails.Position,
          EntryAveragePrice: pos.MoneyUserOrderDetails.AveragePrice,
          Quantity: pos.MoneyUserOrderDetails.Quantity,
          ExitAveragePrice: pos.MoneyUserOrderDetails.ExitAveragePrice,
          Entry: pos.MoneyUserOrderDetails.Entry,
          PositionID: pos.MoneyUserOrderDetails.PositionID,
          Direction: pos.MoneyUserOrderDetails.Direction,
          ExitOrderStatus: pos.MoneyUserOrderDetails.ExitOrderStatus,
          PnL: null,
          OrderStatus: pos.MoneyUserOrderDetails.OrderStatus,
        };
        userLiveTradeDetails.response_data.PositionDetails.push(
          livePositionDetails
        );
      } else {
        console.error('Position property is null or undefined');
      }
    });
    return userLiveTradeDetails;
  } catch (error) {
    // Handle errors here
    console.error('Error fetching data:', error);
    return userLiveTradeDetails;
  }
};

// fetchData('73','strat_0008').then(data =>{
//   let positionDetails : any[] = data.getPositionIdsByStrategyId;
//   let userLiveTradeDetails: UserLiveTradeAPIInterface = {
//     response_data: {
//       PositionDetails: []
//     },
//     response_code: 0,
//     error_code: '',
//     error_message: '',
//     request_id: '',
//     time_taken: 0,
//     request_timestamp: '',
//     response_message: ''
//   };
//   positionDetails.forEach(pos =>{
//     let livePositionDetails: PositionDetails = {
//       Position: pos.MoneyUserOrderDetails.Position,
//       EntryAveragePrice: pos.MoneyUserOrderDetails.AveragePrice,
//       Quantity: pos.MoneyUserOrderDetails.Quantity,
//       ExitAveragePrice: pos.MoneyUserOrderDetails.ExitAveragePrice,
//       Entry: pos.MoneyUserOrderDetails.Entry,
//       PositionID: pos.MoneyUserOrderDetails.PositionID,
//       Direction: '',
//       PnL: null
//     };
//     userLiveTradeDetails.response_data.PositionDetails.push(livePositionDetails);
//   })
// console.log("userLiveTradeDetails", userLiveTradeDetails);
// });

// lots edit
export async function editLots(strategyId: string, lots: number): Promise<any> {
  const apiUrl = process.env.REACT_APP_ME_API as string + 'user/subscription/details/edit_lots';

  const accessToken = localStorage.getItem('accessToken');
  if (!accessToken) {
    console.error('Access token not found in local storage');
    return null;
  }

  const requestBody = {
    strategy_id: strategyId,
    lots: lots,
  };

  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    // if (!response.ok) {
    //     throw new Error(`HTTP error! Status: ${response.status}`);
    // }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
}
