import React, { useEffect, useState } from 'react';
import './MarketNews.css';
import { PopularMarketNewsData } from './MarketNewsData';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Header, { CurrentPage } from '../Header/Header';
import Footer from '../Footer';
import useWindowSize from '../Hooks/useWindowSize';
import { Link } from 'react-router-dom';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import { useNavigate } from 'react-router-dom';
import fetchSearchQueryNews, {
  MarketNewsService,
  UserInteractions,
} from './service';
import ProfileimgJpeg from '../../assets/images/profileimg.jpeg';
import Loader from '../Dialogs/Loader';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import { West } from '@mui/icons-material';
import useAppStore from '../../store/app.store';
import Toast from '../../Utils/Toast';
import { fetchHomePageData } from '../../service/apiService';
function MarketNews() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [isChanged, setisChanged] = useState(false);
  const [isNewsDropDownOpen, setIsNewsDropDownOpen] = useState(false);
  const [isShowAllDropDownOpen, setIsShowAllDropDownOpen] = useState(false);
  const [selectedNewsOption, setSelectedNewsOption] = useState('Top News');
  const [showAllSelected, setShowAllSelected] = useState('Show All');
  const [searchString, setSearchString] = useState('');
  const navigate = useNavigate();
  const { isAuthenticated } = useAppStore();

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'high':
        return '#2ACD1C';

      case 'low':
        return '#F82929';

      case 'medium':
        return '#E7AF1D';

      default:
        return 'black';
    }
  };

  function UpDownIcon() {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='9'
        height='12'
        fill='none'
        viewBox='0 0 9 12'
        className='ml-5'
      >
        <path
          fill='#000'
          fillOpacity='0.7'
          d='M0 4.5L4.5 0 9 4.5H0zM0 7.2l4.5 4.5L9 7.2H0z'
        ></path>
      </svg>
    );
  }

  function SearchIcon() {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='17'
        height='15'
        fill='none'
        viewBox='0 0 17 15'
      >
        <path
          fill='#000'
          fillOpacity='0.7'
          d='M12.15 9.434h-.768l-.272-.232c.952-.977 1.526-2.247 1.526-3.627C12.636 2.495 9.807 0 6.318 0 2.828 0 0 2.496 0 5.575s2.828 5.574 6.318 5.574c1.565 0 3.003-.506 4.111-1.346l.263.24v.677l4.86 4.28L17 13.722l-4.85-4.288zm-5.832 0c-2.42 0-4.374-1.724-4.374-3.86 0-2.135 1.954-3.859 4.374-3.859s4.374 1.724 4.374 3.86c0 2.135-1.954 3.859-4.374 3.859z'
        ></path>
      </svg>
    );
  }

  function StarIcon() {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='20'
        fill='none'
        viewBox='0 0 20 20'
      >
        <path
          fill='#E7AF1D'
          d='M12.43 8L10 0 7.57 8H0l6.18 4.41L3.83 20 10 15.31 16.18 20l-2.35-7.59L20 8h-7.57z'
        ></path>
      </svg>
    );
  }

  function LinkIcon() {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='10'
        height='5'
        fill='none'
        viewBox='0 0 10 5'
      >
        <path
          fill='#5468C4'
          d='M7.5 0h-2v1h2C8.325 1 9 1.675 9 2.5S8.325 4 7.5 4h-2v1h2a2.5 2.5 0 000-5zm-3 4h-2C1.675 4 1 3.325 1 2.5S1.675 1 2.5 1h2V0h-2a2.5 2.5 0 000 5h2V4zM3 2h4v1H3V2z'
        ></path>
      </svg>
    );
  }

  function ThumbsIcon() {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='15'
        fill='none'
        viewBox='0 0 16 15'
      >
        <path
          fill='#000'
          fillOpacity='0.7'
          d='M5.55 14.571h6.244c.576 0 1.069-.346 1.277-.846l2.095-4.891a1.37 1.37 0 00.097-.507V6.94c0-.763-.624-1.388-1.387-1.388H9.498l.659-3.17.02-.222c0-.285-.117-.548-.305-.736L9.137.696 4.565 5.268c-.25.25-.402.596-.402.978v6.938c0 .763.624 1.387 1.387 1.387zm0-8.325l3.011-3.011-.93 3.705h6.245v1.387l-2.082 4.857H5.55V6.246zm-5.55 0h2.775v8.325H0V6.246z'
        ></path>
      </svg>
    );
  }

  function ForwardIcon() {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='12'
        height='10'
        fill='none'
        viewBox='0 0 12 10'
      >
        <path
          fill='#323232'
          d='M7.333 2.667V0L12 4.667 7.333 9.333V6.6C4 6.6 1.667 7.667 0 10c.667-3.333 2.667-6.667 7.333-7.333z'
        ></path>
      </svg>
    );
  }

  function DownIcon() {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='10'
        height='17'
        fill='none'
        viewBox='0 0 10 17'
      >
        <path
          fill='#F82929'
          d='M6.012 12.093V0h-2.12v12.093H.712l4.24 4.018 4.24-4.018h-3.18z'
        ></path>
      </svg>
    );
  }

  function UpIcon() {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='10'
        height='17'
        fill='none'
        viewBox='0 0 10 17'
      >
        <path
          fill='#2ACD1C'
          d='M6.156 4.018V16.11h-2.12V4.018H.856L5.096 0l4.24 4.018h-3.18z'
        ></path>
      </svg>
    );
  }

  function ThumbDownHollowIcon() {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='15'
        fill='none'
        viewBox='0 0 16 15'
      >
        <path
          fill='#000'
          fillOpacity='0.7'
          d='M10.217.696H3.973c-.576 0-1.069.347-1.277.846L.601 6.433a1.37 1.37 0 00-.097.507v1.387c0 .764.624 1.388 1.387 1.388H6.27l-.659 3.17-.02.223c0 .284.117.548.305.735l.735.728L11.202 10c.25-.25.402-.596.402-.978V2.083c0-.763-.624-1.387-1.387-1.387zm0 8.325l-3.011 3.011.93-3.705H1.89V6.94l2.082-4.857h6.244v6.938zM12.992.696h2.775V9.02h-2.775V.696z'
        ></path>
      </svg>
    );
  }

  function MarketNewsBodyTitle(props) {
    const title = props.title;
    return (
      <div className='marketnews-body-title'>
        <StarIcon /> {title}
        <div className='marketnews-body-title-dot'></div>
      </div>
    );
  }

  const [downVotes, setDownVotes] = useState(0);
  const [metricsData, setMetricsData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchHomePageData(true);
        if (data != undefined) setMetricsData(data);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, []);

  function MarketNewsBodyContent(props) {
    const livemintImg = 'https://www.livemint.com/lm-img/icons/mintfavi.svg';
    const moneycontrolImg =
      'https://images.moneycontrol.com/mcnews/images/favicon.ico';
    const economicsImg =
      'https://economictimes.indiatimes.com/icons/etfavicon.ico';
    const investingImg = 'https://www.investing.com/favicon.ico';
    const userEngagement = props.userEngagement;
    //const img = props.img;
    let img = '';
    const title = props.title;
    const time = props.time;
    const desc = props.desc;
    const link = props.link;
    const tags = [];
    const like = userEngagement.likeCount || 0;
    const dislike = userEngagement.dislikeCount || 0;
    const up = userEngagement.upvoteCount || 0;
    const down = userEngagement.downvoteCount || 0;
    const share = userEngagement.shareCount || 0;
    let isLiked = userEngagement.isLiked || false;
    let isDisLiked = userEngagement.isDisliked || false;
    const isUpvoted = userEngagement.isUpvoted || false;
    const isDownvoted = userEngagement.isDownvoted || false;
    const previewLink = link.split('/')[2];
    const feedId = props.feedId || '';
    const redirectLink =
      link.startsWith('http://') || link.startsWith('https://')
        ? link
        : 'http://' + link;

    if (link.includes('economictimes')) {
      img = economicsImg;
    } else if (link.includes('investing')) {
      img = investingImg;
    } else if (link.includes('moneycontrol')) {
      img = moneycontrolImg;
    } else if (link.includes('livemint')) {
      img = livemintImg;
    }

    const convertDateToTime = (dateString) => {
      const givenDate = new Date(dateString);
      const currentDate = new Date();
      // IST offset is +5:30 hours, so we need to subtract this offset
      const IST_OFFSET = 5.5 * 60 * 60 * 1000; // in milliseconds

      // Convert the date to UTC by subtracting the IST offset
      const givenDateInUTC = new Date(givenDate.getTime() - IST_OFFSET);
      // Calculate the difference in milliseconds
      const diffInMilliseconds = currentDate - givenDateInUTC;

      // Calculate the difference in hours
      const diffInHours = diffInMilliseconds / (1000 * 60 * 60);

      // Check if the difference is greater than 24 hours
      if (diffInHours >= 24) {
        const diffInDays = Math.floor(diffInHours / 24);
        return `${diffInDays} d`;
      } else if (diffInHours >= 1) {
        const diffInWholeHours = Math.floor(diffInHours);
        return `${diffInWholeHours} h`;
      } else {
        const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
        return `${diffInMinutes} m`;
      }
    };

    async function handleReaction(reaction, feedID) {
      // console.log(reaction, feedID);
      if (!isAuthenticated && reaction !== 'share') {
        Toast.error('Login before liking or disliking the post');
        return;
      }
      if (isLiked && reaction === 'dislike') {
        Toast.error('You have already liked this post. Please undo your like to dislike it.');
        return;
      }
      if (isDisLiked && reaction === 'like') {
        Toast.error('You have already disliked this post. Please undo your dislike to like it.');
        return;
      }

      const response = await UserInteractions(reaction, feedID);
      console.log(response);
      setisChanged(!isChanged);
    }

    const handleShare = async (link, reaction, feedID) => {
      console.log(link, reaction, feedID);
      try {
        await navigator.clipboard.writeText(link);
        handleReaction(reaction, feedID);
        Toast.success('Link copied to clipboard!');
      } catch (error) {
        console.error('Failed to copy link: ', error);
        Toast.error('Failed to copy link.');
      }
    };

    useEffect(() => {
      if (!isAuthenticated) {
        isLiked = false;
        isDisLiked = false
      } else {
        isLiked = userEngagement.isLiked || false;
      }
    }, [isAuthenticated]);

    return (
      <div className='marketnews-body-content'>
        <div className='marketnews-body-content-title'>
          <div className='marketnews-body-content-title-text'>{title}</div>
          <div className='marketnews-body-content-title-time'>
            {convertDateToTime(time)}
          </div>
        </div>
        <div className='marketnews-body-content-description'>{desc}</div>
        <div className='marketnews-body-content-bottom'>
          <div className='marketnews-body-content-bottom-parts marketnews-body-content-bottom-part-1'>
            <img src={img} alt='profile' />
            <a href={redirectLink} target='_blank' rel='noopener noreferrer'>
              {previewLink} <LinkIcon />{' '}
            </a>
            {tags.map((tag, index) => {
              return <button key={index}>{tag}</button>;
            })}
          </div>
          <div className='marketnews-body-content-bottom-parts marketnews-body-content-bottom-part-2'>
            <button onClick={() => handleReaction('like', feedId)}>
              {isLiked ? (
                <ThumbUpAltIcon className='mini-icons' />
              ) : (
                <ThumbsIcon className='mini-icons' />
              )}{' '}
              {like}
            </button>
            <button onClick={() => handleReaction('dislike', feedId)}>
              {isDisLiked ? (
                <ThumbDownAltIcon className='mini-icons' />
              ) : (
                <ThumbDownHollowIcon className='mini-icons' />
              )}
              {dislike}
            </button>
            <button onClick={() => handleReaction('upvote', feedId)}>
              <UpIcon /> {up}
            </button>
            <button onClick={() => handleReaction('downvote', feedId)}>
              <DownIcon /> {down}
            </button>
            <button onClick={() => handleShare(link, 'share', feedId)}>
              <ForwardIcon />
            </button>
          </div>
        </div>
      </div>
    );
  }

  const [LatestMarketNewsData, setLatestMarketNewsData] = React.useState([]);
  const [pageInfo, setPageInfo] = React.useState(null);

  useEffect(() => {
    const payload = {
      after_cursor: '',
      before_curose: '',
    };
    if (searchString.length >= 3) {
      setLatestMarketNewsData([]);
      handleSearchQuery();
    } else {
      MarketNewsService.getMarketNews(payload).then((response) => {
        setLatestMarketNewsData(response.response_data?.news);
        if (response.response_data?.news.length > 0) {
          setTimeout(() => {
            setIsLoading(false);
          }, 3000);
        } else {
          setTimeout(() => {
            setIsLoading(false);
          }, 5000);
        }
        setPageInfo(response.response_data?.page_info);
      });
    }
  }, [isChanged, searchString]);

  //console.log(LatestMarketNewsData);

  // useEffect(() => {
  //   if (LatestMarketNewsData && LatestMarketNewsData.length > 0) {
  //     setTimeout(() => {
  //       setIsLoading(false);
  //     }, 4000);
  //   }else{
  //     setTimeout(() => {
  //       setIsLoading(false);
  //     }, 4000);
  //   }
  // }, [LatestMarketNewsData]);

  const handleFetchData = (type, cursor) => {
    if (type === 'prev') {
      const payload = {
        after_cursor: '',
        before_curose: cursor,
      };
      MarketNewsService.getMarketNews(payload).then((response) => {
        setLatestMarketNewsData(response.response_data.news);
        setPageInfo(response.response_data.page_info);
      });
    } else {
      const payload = {
        after_cursor: cursor,
        before_curose: '',
      };
      MarketNewsService.getMarketNews(payload).then((response) => {
        setLatestMarketNewsData(response.response_data?.news);
        setPageInfo(response.response_data?.page_info);
      });
    }
  };

  const handleNewsDropdown = () => {
    setIsNewsDropDownOpen(!isNewsDropDownOpen);
  };

  const handleNewsSelection = (selectedOption) => {
    setSelectedNewsOption(selectedOption);
    setIsNewsDropDownOpen(!isNewsDropDownOpen);
  };
  const handleShowAllDropdown = () => {
    setIsShowAllDropDownOpen(!isShowAllDropDownOpen);
  };

  const handleShowAllSelection = (selectedOption) => {
    setShowAllSelected(selectedOption);
    setIsShowAllDropDownOpen(!isShowAllDropDownOpen);
  };

  const handleSearchQuery = async () => {
    try {
      const results = await fetchSearchQueryNews(searchString);
      // console.log(results);
      setLatestMarketNewsData(results);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLatestMarketNewsData([]);
    }
  };

  const formatMoneyManaged = (value) => {
    if (value >= 10000000) {
      return `${(value / 10000000).toFixed(2)} Cr`;
    } else if (value >= 100000) {
      return `${(value / 100000).toFixed(2)} Lakh`;
    } else {
      return value?.toString();
    }
  };

  const windowSize = useWindowSize();
  const promotionalContent = [
    {
      text1: `${
        metricsData?.['total_ai_users'] || 0
      }+ Users are using moneyy.ai now.`,
      buttonText: 'Explore Strategies',
      // Add more promotional content objects as needed
    },
    {
      text1: `${formatMoneyManaged(
        metricsData?.['total_capital_deployed'] || 0
      )} Capital Deployed.`,
      buttonText: 'Explore Strategies',
      // Add more promotional content objects as needed
    },
    {
      text1: `${metricsData?.['total_backtest'] || 0}+ Backtest are done till now.`,
      buttonText: 'Explore Backtest',
      // Add more promotional content objects as needed
    },
    {
      text1: `${metricsData?.['no_of_strategies'] || 0}+ Strategies running for now.`,
      buttonText: 'Explore Strategies',
      // Add more promotional content objects as needed
    },
    {
      text1: `${
        metricsData?.['total_broker'] || 0
      }+ Brokers connected with us.`,
      buttonText: 'Explore Strategies',
      // Add more promotional content objects as needed
    }
  ];

  return (
    <>
      <Header currentPage={CurrentPage.Market_News} />
      {isLoading ? (
        <Loader />
      ) : (
        <div className='marketnews-container'>
          <h5 className='marketnewsrankings'>
            {windowSize <= 800 ? (
              <West
                onClick={() => {
                  navigate('/');
                }}
                style={{
                  cursor: 'pointer',
                  position: 'absolute',
                  left: '-33px',
                }}
              />
            ) : (
              ''
            )}
            Market Feed in Real Time
            <div className='marketnewsrankings-btm-line'></div>
          </h5>
          <hr className='marketnews-horizontal-line' />
          <div className='marketnews-parent-pages'>
            <div className='marketnews-part1 select-dropdown'></div>
            <div className='marketnews-part2'>
              <div className='marketnews-part2-icon-cont'>
                <SearchIcon />
              </div>
              <input
                type='search'
                className='marketnews-part2-search-input'
                placeholder='Search...'
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
              />
            </div>
          </div>
          {windowSize <= 480 && (
            <div className='marketnews-part1 marketnews-part1-responsive'>
              <div className='marketnews-part1-btn-container'>
                <button
                  className='marketnews-part1-btn'
                  style={{ color: 'rgba(0, 0, 0, 0.70)' }}
                  onClick={handleNewsDropdown}
                >
                  {selectedNewsOption}{' '}
                  {isNewsDropDownOpen ? (
                    <ArrowDropUpOutlinedIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </button>
                {isNewsDropDownOpen && (
                  <div className='dropdown-options-container'>
                    <button
                      className='marketnews-part1-btn-option'
                      style={{ color: 'rgba(0, 0, 0, 0.70)' }}
                      onClick={() => handleNewsSelection('Top News')}
                    >
                      Top News
                    </button>
                    <button
                      className='marketnews-part1-btn-option'
                      style={{ color: 'rgba(0, 0, 0, 0.70)' }}
                      onClick={() => handleNewsSelection('All News')}
                    >
                      All News
                    </button>
                    <button
                      className='marketnews-part1-btn-option'
                      style={{ color: 'rgba(0, 0, 0, 0.70)' }}
                      onClick={() => handleNewsSelection('Blog')}
                    >
                      Blog
                    </button>
                    <button
                      className='marketnews-part1-btn-option'
                      style={{ color: 'rgba(0, 0, 0, 0.70)' }}
                      onClick={() => handleNewsSelection('Media')}
                    >
                      Media
                    </button>
                    <button
                      className='marketnews-part1-btn-option'
                      style={{ color: 'rgba(0, 0, 0, 0.70)' }}
                      onClick={() => handleNewsSelection('New Sites')}
                    >
                      New Sites
                    </button>
                  </div>
                )}
              </div>
              <div className='marketnews-part1-btn-container'>
                <button
                  className='marketnews-part1-btn'
                  style={{ color: 'rgba(0, 0, 0, 0.70)' }}
                  onClick={handleShowAllDropdown}
                >
                  {showAllSelected}{' '}
                  {isShowAllDropDownOpen ? (
                    <ArrowDropUpOutlinedIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </button>
                {isShowAllDropDownOpen && (
                  <div className='dropdown-options-container'>
                    <button
                      className='marketnews-part1-btn-option'
                      style={{ color: 'rgba(0, 0, 0, 0.70)' }}
                      onClick={() => handleShowAllSelection('Show All')}
                    >
                      Show All
                    </button>
                    <button
                      className='marketnews-part1-btn-option'
                      style={{ color: 'rgba(0, 0, 0, 0.70)' }}
                      onClick={() => handleShowAllSelection('Latest')}
                    >
                      Latest
                    </button>
                    <button
                      className='marketnews-part1-btn-option'
                      style={{ color: 'rgba(0, 0, 0, 0.70)' }}
                      onClick={() => handleShowAllSelection('Popular')}
                    >
                      Popular
                    </button>
                    <button
                      className='marketnews-part1-btn-option'
                      style={{ color: 'rgba(0, 0, 0, 0.70)' }}
                      onClick={() => handleShowAllSelection('Commented')}
                    >
                      Commented
                    </button>
                    <button
                      className='marketnews-part1-btn-option'
                      style={{ color: 'rgba(0, 0, 0, 0.70)' }}
                      onClick={() => handleShowAllSelection('Top Saved')}
                    >
                      Top Saved
                    </button>
                    <button
                      className='marketnews-part1-btn-option'
                      style={{ color: 'rgba(0, 0, 0, 0.70)' }}
                      onClick={() => handleShowAllSelection('Important')}
                    >
                      Important
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}

          <div className='marketnews-body'>
            <div style={{ display: 'flex' }}>
              <MarketNewsBodyTitle title='Latest' />
            </div>
            <>
              {LatestMarketNewsData && LatestMarketNewsData.length > 0 ? (
                LatestMarketNewsData.map((item, index) => (
                  <span key={index}>
                    <MarketNewsBodyContent
                      key={item.feedID}
                      img={item.feedIcon}
                      title={item.feedTitle}
                      time={item.feedPublishedDate}
                      desc={item.feedDescription}
                      link={item.feedLink}
                      tags={item.tags}
                      like={item.likes}
                      dislike={item.dislikes}
                      up={item.upvotes}
                      down={item.downvotes}
                      feedId={item.feedID}
                      userEngagement={item.user_engagements}
                    />
                    {(index + 1) % 10 === 0 && (
                      <div className='promo-box-container'>
                        <div className='promo-box'>
                          <div className='promo-box-parts'>
                            <div className='promo-box-parts-text1'>
                              {
                                promotionalContent[
                                  (Math.floor((index + 1) / 10) % 10) - 1
                                ]?.text1
                              }
                            </div>
                          </div>
                          <div className='promo-box-parts'>
                            <button
                              onClick={() => {
                                const currentPromoContent =
                                  promotionalContent[
                                    (Math.floor((index + 1) / 10) % 10) - 1
                                  ]?.buttonText;
                                if (
                                  currentPromoContent === 'Explore Strategies'
                                ) {
                                  navigate('/strategy');
                                } else {
                                  navigate('/backtesting');
                                }
                                window.scrollTo(0, 0); // Scroll to the top of the page directly
                              }}
                            >
                              {
                                promotionalContent[
                                  (Math.floor((index + 1) / 10) % 10) - 1
                                ]?.buttonText
                              }
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </span>
                ))
              ) : (
                <span
                  style={{
                    padding: '30px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  No News results found
                </span>
              )}
            </>
          </div>
          <div className='marketnews-pages'>
            {pageInfo && (
              <div>
                <button
                  className='marketnews-btn1'
                  style={{
                    color: 'rgba(0, 0, 0, 0.70)',
                    cursor: pageInfo.hasPreviousPage
                      ? 'pointer'
                      : 'not-allowed',
                  }}
                  disabled={!pageInfo.hasPreviousPage}
                  onClick={() => handleFetchData('prev', pageInfo.startCursor)}
                >
                  {' '}
                  <ArrowBackIosIcon
                    style={{ fontSize: '10px' }}
                  /> Previous{' '}
                </button>
                <button
                  className='marketnews-btn2'
                  style={{
                    color: 'rgba(0, 0, 0, 0.70)',
                    cursor: pageInfo.hasNextPage ? 'pointer' : 'not-allowed',
                  }}
                  disabled={!pageInfo.hasNextPage}
                  onClick={() => handleFetchData('end', pageInfo.endCursor)}
                >
                  Next
                  <ArrowForwardIosIcon style={{ fontSize: '10px' }} />
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      <Footer special={true} />
    </>
  );
}

export default MarketNews;
