import { isTokenExpired } from "../API/DecodeJWTFunction";
import { ApiResponse, UserLeaderboard } from "../API/LeaderboardAPI";
import { ApiResponseLiveToday, SubscriptionDetailInterface } from "../API/LiveTodayAPI";
import { APICallOptions, SubscribeToStrategyRequest, SubscribeToStrategyResponse } from "../API/StrategiesAPIS";

interface CacheEntry {
    timestamp: number;
    data: any;
  }

  interface DeleteBacktestApiResponse {
    response_code: number;
    response_message: string;
  }
  
  const cache: Record<string, CacheEntry> = {};
  
  const CACHE_DURATION = 60 * 60 * 1000; // 60 minutes in milliseconds
  
  // Function to check cache and determine if the cached data is still valid
  function isCacheValid(key: string): boolean {
    const cacheEntry = cache[key];
    if (!cacheEntry) return false;
  
    const currentTime = Date.now();
    return currentTime - cacheEntry.timestamp < CACHE_DURATION;
  }
  
  // Function to get data from cache
  function getFromCache(key: string) {
    return cache[key]?.data;
  }
  
  // Function to store data in cache
  function storeInCache(key: string, data: any) {
    cache[key] = { timestamp: Date.now(), data };
  }

  // Function to clear specific API caches
 export function clearCache() {
    // Reset the cache object
    Object.keys(cache).forEach((key) => delete cache[key]);
  }
  
  
  // Common API Utility Function to fetch data from API
async function fetchDataAccessToken(isCache:boolean,isTokenCheck:boolean,apiUrl: string, method: string, body?: any) {
  const token = localStorage.getItem('accessToken');
  if(isTokenCheck){
    if (isTokenExpired(token)) {
      return;
    }
  }
    // Check cache first
    if ( isCache && isCacheValid(apiUrl)) {
      
      return getFromCache(apiUrl);
    }
     const apiresponseData = await fetch(apiUrl, {
      method,
      headers:{
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: body ? JSON.stringify(body) : undefined,
    });

    if(apiresponseData.ok){
      const response = await apiresponseData.json();
      // Store the data in cache if enabled
      if(isCache){
       storeInCache(apiUrl, response);
     }
     return response;
    }
    else{
      throw new Error('Failed to fetch data');
    }
  }

  async function fetchDataXApi(isCache:boolean,isTokenCheck:boolean,apiUrl: string, method: string, body?: any) {
    const token = localStorage.getItem('accessToken');
    if(isTokenCheck){
      if (isTokenExpired(token)) {
        return;
      }
    }
      // Check cache first
      if ( isCache && isCacheValid(apiUrl)) {
        
        return getFromCache(apiUrl);
      }
       const apiresponseData = await fetch(apiUrl, {
        method,
        headers:{
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_X_API_KEY as string,
        },
        body: body ? JSON.stringify(body) : undefined,
      });
  
      if(apiresponseData.ok){
        const response = await apiresponseData.json();
        // Store the data in cache if enabled
        if(isCache){
         storeInCache(apiUrl, response);
       }
       return response;
      }
      else{
        throw new Error('Failed to fetch data');
      }
    }

    async function fetchDataAccessTokenXApi(isCache:boolean,isTokenCheck:boolean,apiUrl: string, method: string, body?: any) {
      const token = localStorage.getItem('accessToken');
      if(isTokenCheck){
        if (isTokenExpired(token)) {
          return;
        }
      }
        // Check cache first
        if ( isCache && isCacheValid(apiUrl)) {
          
          return getFromCache(apiUrl);
        }
         const apiresponseData = await fetch(apiUrl, {
          method,
          headers:{
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            'x-api-key': process.env.REACT_APP_X_API_KEY as string,
          },
          body: body ? JSON.stringify(body) : undefined,
        });
    
        if(apiresponseData.ok){
          const response = await apiresponseData.json();
          // Store the data in cache if enabled
          if(isCache){
           storeInCache(apiUrl, response);
         }
         return response;
        }
        else{
          throw new Error('Failed to fetch data');
        }
      }
  
  
  // API Service Function for getAboutMe
export async function getAboutMe( isCache:boolean,accessToken?: string): Promise<any> {
    const apiUrl = process.env.REACT_APP_ME_API as string + 'me';
    try {
      const response = await fetchDataAccessToken(isCache,true,apiUrl, 'GET');
      return response;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

  
  // API Service Function for getHomePageData

 export async function fetchHomePageData(isCache:boolean) {
    const apiUrl = process.env.REACT_APP_BASE_URL as string + 'home-page/details';
    try {
      const response = await fetchDataXApi(isCache,false,apiUrl, 'GET');
      return response.response_data;
    } catch (error: any) {
      throw new Error(`Error: ${error?.message}`);
    }
  }

  // API Service Function for getLeaderboardData

 export async function fetchUserLeaderboard(isCache:boolean,payload?:any): Promise<UserLeaderboard[]> {
    const apiUrl = process.env.REACT_APP_BASE_URL as string + 'leaderboard';
    try {
      const response = await fetchDataAccessTokenXApi(isCache,false,apiUrl, 'POST',payload);
     
      return response.body.Users_Leaderboard;
    } catch (error) {
      console.error('Error fetching user leaderboard:', error);
      return [];
    }
  }

  // function to save backtest reslts with object url & user ID
export async function saveBacktestResults(isCache:boolean,requestBody: any): Promise<void> {
  const apiUrl = (process.env.REACT_APP_BASE_URL as string) + 'backtest/save';
 
  try {
    const response = await fetchDataAccessToken(isCache,true,apiUrl, 'POST', requestBody);
    return response;
  } catch (error: any) {
    console.error('Error saving backtest:', error);
    throw error;
  }
}


// navbar page api call for latest 3 backtests

export async function getBacktestsData(isCache:boolean,userID: number): Promise<any> {
  const apiUrl = (process.env.REACT_APP_BASE_URL as string) + 'backtest/get';
  
  try {
    const response = await fetchDataAccessToken(isCache,true,apiUrl, 'POST', {});
    return response.response_data;
  } catch (error) {
    console.log('error in fetching backtest data', error);
    throw error;
  }
}

// function to delete saved backtest with user ID & backtest ID

export async function deleteSavedBacktest(isCache:boolean,userId: number,backtest_id: number): Promise<DeleteBacktestApiResponse> {
  const apiUrl =
    (process.env.REACT_APP_BASE_URL as string) + 'backtest/delete';

  const currentDate = new Date();
  const day = String(currentDate.getDate()).padStart(2, '0');
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const year = currentDate.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;
  const requestBody = {
    user_id: userId,
    // name: name,
    // date: formattedDate,
    backtest_id: backtest_id,
  };

  try {
    const response = await fetchDataAccessToken(isCache,true,apiUrl, 'POST', requestBody);
    return response;
  } catch (error) {
    console.error('Error deleting backtest:', error);
    throw error;
  }
}


// function to do user kill switch
export async function LiveTodayKillAPI(isCache:boolean,userId: string,strategyId: string): Promise<ApiResponseLiveToday> {
  const apiUrl = process.env.REACT_APP_BASE_URL as string + 'user-kill-switch';

  try {
    const requestBody = {
      userId: userId,
      strategyId: strategyId,
    };

    const response = await fetchDataAccessToken(isCache,true,apiUrl, 'POST', requestBody);
    return response;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}


// function to get startegy subscription pause and resume
export async function subscriptionPauseResumeAPI(isCache:boolean,strategyId: string): Promise<ApiResponseLiveToday> {
  const apiUrl = process.env.REACT_APP_ME_API as string + 'subscription_pause_resume/';

  const requestBody = {
    strategy_id: strategyId,
  };
  try {
    const response = await fetchDataAccessToken(isCache,true,apiUrl, 'POST', requestBody);
    return response;
  
  } catch (error: any) {
    throw new Error(`Error: ${error.message}`);
  }
}

// function to get startegy subscription details
export async function getSubscriptionDetails(isCache:boolean): Promise<SubscriptionDetailInterface> {
  const apiUrl = process.env.REACT_APP_ME_API as string + 'user/subscription/details/';

  try {
    const response: SubscriptionDetailInterface = await fetchDataAccessToken(isCache,false,apiUrl, 'GET');
    const strategy_name_default = '';
    response.Response_data.forEach((obj) => {
      if (obj.strategy_name == null || obj.strategy_name == undefined) {
        obj.strategy_name = strategy_name_default;
      } else {
        console.log('Position property is null or undefined');
      }
    });
      return response;
  } catch (error: any) {
    throw new Error(`Error: ${error.message}`);
  }
}

// function to subscribe to strategy
export async function SubscribeToStrategyAPI(isCache:boolean,
  requestData: SubscribeToStrategyRequest
): Promise<SubscribeToStrategyResponse> {
  const apiUrl = process.env.REACT_APP_ME_API as string + 'SubscribeToStrategy/';

  try {
    const response: SubscribeToStrategyResponse = await fetchDataAccessToken(isCache,true,apiUrl, 'POST', requestData);
    return response;
  } catch (error: any) {
    throw new Error(`Error: ${error.message}`);
  }
}

// function to get MockRunTestsAPI
export async function MockRunTestsAPI(isCache:boolean,options: APICallOptions): Promise<any> {
  const apiUrl = process.env.REACT_APP_ME_API as string + 'mock/runtests';

  try {
    const response = await fetchDataAccessToken(isCache,true,apiUrl, 'POST', options);
    return response;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

// function to get StrategyListAPI
export async function getStrategyList(isCache:boolean): Promise<any[]> {
  const apiUrl = process.env.REACT_APP_BASE_URL as string + 'strategy/strategy-list-v2';

  try {
    const response = await fetchDataXApi(isCache,false,apiUrl, 'POST', {});
    return response.response_data;
  } catch (error) {
    console.error('Error');
    return [];
  }
}


// function to get StrategyDetailsAPI
export async function getStrategyDetails(isCache:boolean,id: any): Promise<any> {
  const apiUrl = process.env.REACT_APP_BASE_URL as string + 'strategy/strategy-details';

  const requestBody = {
    strategy_id: id,
  };

  try {
    const response = await fetchDataAccessTokenXApi(isCache,false,apiUrl, 'POST', requestBody);
    return response;
  } catch (error) {
    console.error('Error fetching strategy details:', error);
    throw error;
  }
}