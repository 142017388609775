import { jwtDecode } from 'jwt-decode';

export const decodeJwtToken = (): { user_id?: string } | null => {
  try {
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      console.error('Access token not found in localStorage');
      return null;
    }

    // deconding the JWT token
    const decodedToken: { user_id?: string } = jwtDecode(accessToken) as {
      user_id?: string;
    };

    return decodedToken;
  } catch (error) {
    console.error('Error decoding JWT token:', error);
    return null;
  }
};



export const isTokenExpired = (token: any) => {
  if (!token) return true;

  try {
    const decodedToken = jwtDecode(token);

    if (decodedToken.exp) {
      // Get the current time in seconds
      const currentTime = Math.floor(Date.now() / 1000);

      // Check if the token has expired
      return decodedToken.exp < currentTime;
    }

    return true;
  } catch (error) {
    console.error("Error decoding token:", error);
    return true;
  }
};

