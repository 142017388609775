import {
  addDaysToDate,
  generateRandomString,
} from '../components/Profile/PlanAndPricing';

export interface InvoiceInfo {
  city: string;
  company_id: number;
  company_name: string;
  gst_number: string;
  pin_code: string;
  state: string;
}

export interface UserDetails {
  email: string;
  phone_number: string;
  name: string;
  is_active: boolean;
  joined_date: string;
  user_image: string;
  invoice_information: InvoiceInfo;
  user_address: {
    street_address_1: string;
    street_address_2: string;
    city: string;
    state: string;
    postal_code: string;
    country: string;
  };
}

export interface UserData {
  user_plan_details: {
    strategy: PlanDetails;
    backtesting: PlanDetails;
  };
  user_pnl_data: any;
  user_backtest_data: any;
  runbacktest_count: {
    last_month_backtesting_count: number;
    total_backtesting_count: number;
  }
}

export interface UpdateUserDetailsInterface {
  general_info: {
    street_address_1: string;
    street_address_2: string;
    city: string;
    state: string;
    postal_code: string;
    country: string;
  };
  invoice_info: {
    company_name: string;
    gst_number: string;
    city: string;
    state: string;
    pin_code: string;
  };
}

interface PlanDetails {
  plan_name: string;
  plan_id: number;
  expiry_date: string;
  plan_type: string;
  max_lots: number;
}

export interface PlanData {
  message: string;
}

export interface CapitalCAGRInterface {
  response_data: {
    average_cagr: number;
    average_minimum_required_capital: number;
  };
}


export async function UpdateUserDetails(
  updateBody: UpdateUserDetailsInterface
) {
  const accessToken = localStorage.getItem('accessToken');

  if (!accessToken) {
    console.error('Access token not found in localStorage');
    return;
  }

  const apiUrl = (process.env.REACT_APP_ME_API as string) + 'signup/update';
  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(updateBody),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();
    if (responseData.Status_code === 200) {
      return responseData.Message;
    } else {
      throw new Error(
        `Update failed with status code: ${responseData.Status_code}`
      );
    }
  } catch (error) {
    console.error('Error updating user details:', error);
  }
}



export async function UpgradePlan(
  userPlan?: any,
  isMonthly?: boolean
): Promise<PlanData> {
  let payload = {};
  if (userPlan && userPlan.plan_type == 'BACK TESTING') {
    payload = {
      invoice_details: {
        issue_date: addDaysToDate(0),
        due_date: isMonthly ? addDaysToDate(30) : addDaysToDate(365),
        reference_number: generateRandomString(),
        currency: 'INR',
      },
      invoice_items: [
        {
          description: isMonthly
            ? 'Premium monthly plan'
            : 'Premium annually Plan',
          quantity: isMonthly ? 1 : 12,
          unit_price: isMonthly ? 999 : 11988,
          total_amount: isMonthly ? 999 : 11988,
        },
      ],
      financial_details: {
        subtotal: isMonthly ? 999 : 11988,
        discount: 0,
        tax: 0,
        around_total: isMonthly ? 999 : 11988,
      },
      payment_details: {
        status: 'Paid',
        payment_method: 'COD',
      },
      user_plan_details: {
        name: 'Premium',
        start_date: addDaysToDate(0),
        expiry_date: isMonthly ? addDaysToDate(30) : addDaysToDate(365),
        plan_type: userPlan.plan_type,
        max_lots: userPlan.max_lots,
      },
    };
  } else {
    const amount = (userPlan.max_lots - userPlan.min_lots) * 10;
    payload = {
      invoice_details: {
        issue_date: addDaysToDate(0),
        due_date: addDaysToDate(30),
        reference_number: generateRandomString(),
        currency: 'INR',
      },
      invoice_items: [
        {
          description: 'Premium monthly plan',
          quantity: userPlan.max_lots,
          unit_price: 10,
          total_amount: amount,
        },
      ],
      financial_details: {
        subtotal: amount,
        discount: 0,
        tax: 0,
        around_total: amount,
      },
      payment_details: {
        status: 'Paid',
        payment_method: 'CREDIT CARD',
      },
      user_plan_details: {
        name: 'Premium',
        start_date: addDaysToDate(0),
        expiry_date: addDaysToDate(30),
        plan_type: userPlan.plan_type,
        max_lots: userPlan.max_lots,
      },
    };
  }
  try {
    const accessToken = localStorage.getItem('accessToken');
    const apiUrl = (process.env.REACT_APP_ME_API as string) + 'plan/upgrade';
    const upgradeResponse = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (!upgradeResponse.ok) {
      throw new Error('Failed to upgrade plan');
    }

    return (await upgradeResponse.json()) as PlanData;
  } catch (error) {
    console.error('Error occurred in plan API:', error);
    throw error;
  }
}

export async function FetchBrokerDetailsAPI(customerId: string) {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const apiUrl =
      (process.env.REACT_APP_BASE_URL as string) +
      'broker-details/get-user-broker-details';
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        customerId: `${customerId}`,
      }),
    });

    const result = await response.json();
    return result;

    // if (result.response_code === 200 && result.response_data.length > 0) {
    //   return result.response_data[0];
    // } else {
    //   console.log('Failed to fetch broker details.');
    //   return null;
    // }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

// formApi.ts

export async function handleBrokerFormSubmit(formData: any) {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const apiUrl =
      (process.env.REACT_APP_BASE_URL as string) +
      'broker-details/user-broker-details';
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    const result = await response.json();
    console.log(result);

    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

//
export async function fetchAverageCAGRAndCapital(): Promise<
  CapitalCAGRInterface['response_data']
> {
  try {
    let apiURL =  process.env.REACT_APP_BASE_URL as string +'cgarcapital';
    const response = await fetch(apiURL, { method: 'GET' });
    const result: CapitalCAGRInterface = await response.json();
    // console.log(result);
    return result.response_data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
