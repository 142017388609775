import React, { useEffect, useState, useRef } from "react";
import "../Leaderboard/Leaderboard.css";
// import LeaderboardData from './LeaderboardData';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Header, { CurrentPage } from "../Header/Header";
import { ReactComponent as BeginnerSVG } from "../../assets/images/beginner-sm.svg";
import { ReactComponent as Level2SVG } from "../../assets/images/level2.svg";
import { ReactComponent as Level1SVG } from "../../assets/images/level1.svg";
import { ReactComponent as ProSVG } from "../../assets/images/pro-sm.svg";
import { ReactComponent as HighSvg } from "../../assets/images/high.svg";
import { ReactComponent as LowSvg } from "../../assets/images/low.svg";
import { ReactComponent as MediumSvg } from "../../assets/images/medium.svg";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import  {
  UserLeaderboard,
} from "../../API/LeaderboardAPI";
import ProfileimgJpeg from "../../assets/images/profileimg.jpeg";
import Loader from "../Dialogs/Loader";
import { West } from "@mui/icons-material";
import useWindowSize from "../Hooks/useWindowSize";
import { formatNumber } from "../../API/FormatNumber";
import { fetchUserLeaderboard } from "../../service/apiService";

function Leaderboard() {
  const [isLoading, setIsLoading] = useState(true);
  const iconRef = useRef(null);
  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case "high":
        return "#2ACD1C";

      case "low":
        return "#F82929";

      case "medium":
        return "#E7AF1D";

      default:
        return "black";
    }
  };

  const UpDownIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="12"
      fill="none"
      viewBox="0 0 9 12"
      className="ml-5"
    >
      <path
        fill="#000"
        fillOpacity="0.7"
        d="M0 4.5L4.5 0 9 4.5H0zM0 7.2l4.5 4.5L9 7.2H0z"
      ></path>
    </svg>
  );

  const [leaderboardData, setLeaderboardData] = useState<UserLeaderboard[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 10;

  async function fetchData(payload?: any) {
    const data = await fetchUserLeaderboard(false,payload);
    // console.log(data);
    setLeaderboardData(data);
    setIsLoading(false);
  }
  useEffect(() => {
    fetchData();
  }, []);

  const maxPages = Math.ceil(leaderboardData.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < maxPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const renderLeaderboardData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentData = leaderboardData.slice(startIndex, endIndex);

    return currentData.map((data, index) => (
      <tr key={index} className="leaderboard-table-row">
        <td className="leaderboard-table-data" style={{ textAlign: "center" }}>
          {data.ranking}
        </td>
        <td
          className="leaderboard-table-data "
          style={{
            textAlign: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            className="profile-img"
            src={data.user_image ? data.user_image : ProfileimgJpeg}
            alt={data.name}
          />{" "}
          <span title={data.name.length > 10 ? data.name : undefined}>
            {data.name.length > 10 ? `${data.name.slice(0, 10)}...` : data.name}
          </span>
        </td>
        <td
          className="leaderboard-table-data"
          style={{ textAlign: "left", paddingLeft: "95px" }}
        >
          {/* {data.icon}  */}
          {/* {data.level === "Beginner" ? <BeginnerSVG/> : {data.level === "Level 1"} : <Level1SVG/> : } */}
          {data.level === "Beginner" ? (
            <BeginnerSVG />
          ) : data.level === "Level 1" ? (
            <Level1SVG />
          ) : data.level === "Level 2" ? (
            <Level2SVG />
          ) : data.level === "Pro" ? (
            <ProSVG />
          ) : data.level === "Intermediate" ? (
            <Level2SVG />
          ) : null}
          {data.level}
        </td>
        <td
          className="leaderboard-table-data bluecolortable"
          style={{ textAlign: "left", paddingLeft: "70px" }}
        >
          {data.no_of_backtests}
        </td>
        <td className="leaderboard-table-data " style={{ textAlign: "center" }}>
          {formatNumber(data.strategies_PnL.toString())}
        </td>
        <td
          className="leaderboard-table-data"
          style={{
            textAlign: "center",
            // color:
            //   Number(data.strategy1) > 0 ? ' #2ACD1C' : '#F82929',
          }}
        >
          {/* {data.strategies_PnL_percentage}% */}
          {formatNumber(data.strategies_PnL_percentage.toString())}%
        </td>
        <td
          className="leaderboard-table-data"
          style={{
            color: getStatusColor(data.market_news_engagement),
            display: "flex",
            alignItems: "center",
            textAlign: "left",
            justifyContent: "center",
          }}
        >
          {data.market_news_engagement}
          {data.market_news_engagement === "Low" ? (
            <LowSvg />
          ) : data.market_news_engagement === "Medium" ? (
            <MediumSvg />
          ) : data.market_news_engagement === "High" ? (
            <HighSvg />
          ) : null}
        </td>
      </tr>
    ));
  };

  const startRange = (currentPage - 1) * itemsPerPage + 1;
  const endRange = Math.min(currentPage * itemsPerPage, leaderboardData.length);
  const totalResults = leaderboardData.length;
  let payload = {};

  const handleSort = (key: string, sortOrder:string) => {

    payload = {
      sort_column: key,
      sort_order: sortOrder
    }
    // setIsLoading(true);
    fetchData(payload);

  }
  const handleIconClick = (e: any, key: string) => {
    const icon = iconRef.current as unknown as HTMLElement; // Type assertion

    const iconRect = icon.getBoundingClientRect(); // Provide a default value

    if (e.clientY < iconRect.top + iconRect.height / 2) {
     // console.log('Clicked on the upper part of the icon',key);
      // Handle the upper part click
      setIsLoading(true);
      handleSort(key,'asc');
    } else {
     // console.log('Clicked on the lower part of the icon',key);
      // Handle the lower part click
      setIsLoading(true);
      handleSort(key,'desc');
    }
  };

  // if (isLoading) {
  //   return <Loader />;
  // }

  const windowSize = useWindowSize();

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="leaderboard-container">
          {/* <h5 className='leaderboardrankings'>
          {windowSize <= 800 && <West
            onClick={() => {
              alert("pressed back button")
            }}
            style={{ cursor: 'pointer' }}
          />}
          Leaderboard Rankings
          <div className='leaderboardrankings-btm-line'></div>
        </h5> */}
          {/* <hr className='leaderboard-horizontal-line' /> */}

          <Link
            className="learnmoreaboutleaderboard"
            to={"/learn-more-about-leaderboard"}
          >
            Learn more about Leaderboard{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="7"
              viewBox="0 0 14 7"
              fill="none"
            >
              <path
                d="M10.5087 4.375H0L0 2.625L10.5087 2.625V0L14 3.5L10.5087 7V4.375Z"
                fill="#2747DD"
              />
            </svg>
          </Link>

          <div className="leaderboard-parent-pages">
            <div className="leaderboard-pages1">{`${startRange} - ${endRange} of ${totalResults} Results`}</div>
            <div className="leaderboard-child1-pages">
              <div className="leaderboard-pages2">
                {currentPage} of {maxPages} pages
              </div>
            </div>
          </div>
          <div className="leaderboard-table-container">
            <table className="leaderboard-table">
              <thead className="leaderboard-table-heading">
                <tr className="leaderboard-table-navitem">
                  <th className="leaderboard-nav" style={{ textAlign: "center" }}>
                    Ranking <span ref={iconRef} onClick={(e) => handleIconClick(e, 'ranking')}>
                      <UpDownIcon />
                    </span>
                  </th>
                  <th
                    className="leaderboard-nav"
                    style={{ textAlign: "left", paddingLeft: "40px" }}
                  >
                    Name <span ref={iconRef} onClick={(e) => handleIconClick(e, 'name')}>
                      <UpDownIcon />
                    </span>
                  </th>
                  <th className="leaderboard-nav" style={{ textAlign: "center" }}>
                    Level <span ref={iconRef} onClick={(e) => handleIconClick(e, 'level')}>
                      <UpDownIcon />
                    </span>
                  </th>
                  <th className="leaderboard-nav" style={{ textAlign: "center" }}>
                    No. of Backtests <span ref={iconRef} onClick={(e) => handleIconClick(e, 'no_of_backtests')}>
                      <UpDownIcon />
                    </span>
                  </th>
                  <th className="leaderboard-nav" style={{ textAlign: "center" }}>
                    Strategies P&L <span ref={iconRef} onClick={(e) => handleIconClick(e, 'strategies_PnL')}>
                      <UpDownIcon />
                    </span>
                  </th>
                  <th className="leaderboard-nav" style={{ textAlign: "center" }}>
                    Strategies P&L % <span ref={iconRef} onClick={(e) => handleIconClick(e, 'strategies_PnL_percentage')}>
                      <UpDownIcon />
                    </span>
                  </th>
                  <th className="leaderboard-nav" style={{ textAlign: "center" }}>
                    Market Feed Engagement <span ref={iconRef} onClick={(e) => handleIconClick(e, 'market_news_engagement')}>
                      <UpDownIcon />
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>{renderLeaderboardData()}</tbody>
            </table>
          </div>
          <div className="leaderboard-prev-next-button-container">
            <button
              className="leaderboard-btn1"
              style={{ color: "rgba(0, 0, 0, 0.70)" }}
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              {" "}
              <ArrowBackIosIcon style={{ fontSize: "10px" }} /> Previous{" "}
            </button>
            <button
              className="leaderboard-btn2"
              style={{ color: "rgba(0, 0, 0, 0.70)" }}
              onClick={handleNextPage}
              disabled={currentPage === maxPages}
            >
              Next
              <ArrowForwardIosIcon style={{ fontSize: "10px" }} />
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default Leaderboard;
