import React, { useEffect, useRef, useState } from 'react';
import { Theme, ThemeTypes } from '../../Utils/Constants';
import { InputAdornment, TextField } from '@mui/material';
import StateInputField from './StateInputField';
import ProfilePic from '../../assets/images/default_user-img.jpg';
import {
  UpdateUserDetails,
  UpdateUserDetailsInterface,
  UserDetails,
} from '../../API/ProfileAPI';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import Loader from '../Dialogs/Loader';
import Dropzone from 'react-dropzone';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { decodeJwtToken } from '../../API/DecodeJWTFunction';
import Toast from '../../Utils/Toast';
import useWindowSize from '../Hooks/useWindowSize';
import { getAboutMe } from '../../service/apiService';

const MyProfile: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [profileImage, setProfileImage] = useState<File | null>(null);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [uploadingDialogOpen, setUploadingDialogOpen] = useState(false);
  var userId: string = '';
  const [imageUrl, setImageUrl] = useState<string>(ProfilePic);
  const [uploadedImageUrl, setUploadedImageUrl] = useState<string>('');

  const windowSize = useWindowSize();

  const [userData, setUserData] = useState<UserDetails | null>(null);
  const [modifiedUserData, setModifiedUserData] = useState<UserDetails | null>(
    null
  );

  const decodedToken = decodeJwtToken();

  userId = String(decodedToken?.user_id ?? '');
  const imageInput = useRef<HTMLInputElement>(null);
  const [updateBody, setUpdateBody] = useState<UpdateUserDetailsInterface>({
    general_info: {
      street_address_1: '',
      street_address_2: '',
      city: '',
      state: '',
      postal_code: '',
      country: '',
    },
    invoice_info: {
      company_name: '',
      gst_number: '',
      city: '',
      state: '',
      pin_code: '',
    },
  });

  function handlePickClick() {
    if (imageInput.current) {
      imageInput.current.click();
    }
  }
  const fetchUserData = async () => {
    try {
      const userData = await getAboutMe(true);
      // console.log(userData);

      setUserData(userData);
      setModifiedUserData(userData);

      if (userData.user_image) {
        setImageUrl(userData.user_image);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchUserData();
  }, []);

  // console.log(userData);

  const uploadUserImage = async () => {
    if (uploadedImageUrl) {
      try {
        const url = process.env.REACT_APP_ME_API as string + 'user/update_image';

        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) {
          throw new Error('Access token not found.');
        }

        const payload = {
          user_image: uploadedImageUrl,
        };

        const response = await fetch(url, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload), // Convert payload to JSON string
        });

        if (response.ok) {
          // Handle successful upload
          console.log('Image uploaded successfully.');
          // const userData = await FetchUserDetails("9949465818", "Titan#12");
          // // console.log(userData);

          // setUserData(userData);
          fetchUserData();
        } else {
          // Handle error response
          const errorData = await response.json();
          console.error('Error uploading image:', errorData.message);
        }
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }
  };

  function base64ToBinary(base64: string): ArrayBuffer {
    const binaryString = atob(base64.split(',')[1]);
    const length = binaryString.length;
    const bytes = new Uint8Array(length);

    for (let i = 0; i < length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    return bytes.buffer;
  }

  function getBase64(file: any, callback: any) {
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      const base64Data = reader.result?.toString() || '';
      const binaryData = base64ToBinary(base64Data);
      callback(binaryData);
    });

    reader.readAsDataURL(file);
  }

  const handleImageUpload = async () => {
    if (profileImage) {
      try {
        //const userId = userData; // Replace '87' with the actual user ID
        const fileFormat = profileImage.name.split('.').pop(); // Replace 'png' with the actual file format
        const url = `${process.env.REACT_APP_PROFILE_PICTURE_URL}/${userId}.${fileFormat}`;

        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) {
          throw new Error('Access token not found.');
        }

        getBase64(profileImage, async function (binaryData: any) {
          const response = await fetch(url, {
            method: 'PUT',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'content-type': `image/${fileFormat}`,
            },
            body: binaryData,
          });
          const imageData = await response.json();
          setUploadedImageUrl(imageData.object_url);
          fetchUserData();
        });

        setUploadDialogOpen(false);
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }
  };

  // Use useEffect to trigger uploadUserImage after uploadedImageUrl changes
  useEffect(() => {
    if (uploadedImageUrl) {
      uploadUserImage(); // Call uploadUserImage when uploadedImageUrl changes
    }
  }, [uploadedImageUrl]);

  const handleImageCancel = () => {
    setUploadDialogOpen(false);
    setProfileImage(null);
    setUploadingDialogOpen(false);
  };

  const handleImageChange = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file) {
      setProfileImage(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
    } else {
      setProfileImage(null);
    }
  };

  function formatDate(dateString: any): string {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    };
    return date.toLocaleDateString('en-GB', options);
  }
  const handleInputChange = (field: string, value: string) => {
    if (!modifiedUserData) return;

    setModifiedUserData((prevUserData) => {
      if (!prevUserData) return prevUserData;

      return {
        ...prevUserData,
        user_address: {
          ...prevUserData.user_address,
          [field]: value,
        },
      };
    });
  };
  const handleInvoiceInputChange = (field: string, value: string) => {
    if (!modifiedUserData) return;

    setModifiedUserData((prevUserData) => {
      if (!prevUserData) return prevUserData;

      return {
        ...prevUserData,
        invoice_information: {
          ...prevUserData.invoice_information,
          [field]: value,
        },
      };
    });
  };

  useEffect(() => {
    if (modifiedUserData) {
      setUpdateBody({
        general_info: {
          street_address_1: modifiedUserData.user_address.street_address_1,
          street_address_2: modifiedUserData.user_address.street_address_2,
          city: modifiedUserData.user_address.city,
          state: modifiedUserData.user_address.state,
          postal_code: modifiedUserData.user_address.postal_code,
          country: modifiedUserData.user_address.country,
        },
        invoice_info: {
          company_name: modifiedUserData.invoice_information.company_name,
          gst_number: modifiedUserData.invoice_information.gst_number,
          city: modifiedUserData.invoice_information.city,
          state: modifiedUserData.invoice_information.state,
          pin_code: modifiedUserData.invoice_information.pin_code,
        },
      });
    }
  }, [modifiedUserData]);

  const handleSaveButton = async () => {
    try {
      const message = await UpdateUserDetails(updateBody);
      if (message) {
        Toast.success('User details updated successfully');
        // console.log('User details updated successfully:', message);
      } else {
        // console.error('Failed to update user details.');
        Toast.error('Failed to update user details.');
      }
    } catch (error) {
      console.error('Error updating user details:', error);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  function TrashIcon(props: any) {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='14'
        height='18'
        fill='none'
        viewBox='0 0 14 18'
        {...props}
      >
        <path
          fill='#000'
          fillOpacity='0.7'
          d='M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V4H1v12zm2.46-7.12l1.41-1.41L7 9.59l2.12-2.12 1.41 1.41L8.41 11l2.12 2.12-1.41 1.41L7 12.41l-2.12 2.12-1.41-1.41L5.59 11 3.46 8.88zM10.5 1l-1-1h-5l-1 1H0v2h14V1h-3.5z'
        ></path>
      </svg>
    );
  }

  return (
    <div
      className='special-width-background'
      style={
        windowSize <= 500
          ? styles.responsiveProfile
          : { background: 'transparent' }
      }
    >
      <p style={styles.memberSince}>
        Member Since {formatDate(userData?.joined_date)}
      </p>
      <div
        style={
          windowSize <= 500 ? styles.containerResponsive : styles.container
        }
      >
        {windowSize <= 500 ? (
          <p style={styles.title}>General Information</p>
        ) : (
          ''
        )}
        <div
          style={
            windowSize <= 500
              ? styles.leftContainerResponsive
              : styles.leftContainer
          }
        >
          <img src={imageUrl} alt='logo' style={styles.profileImageStyle} />
          <EditIcon
            style={styles.editIcon}
            onClick={() => setUploadDialogOpen(true)}
          />
        </div>

        {/* Upload Image Popup */}
        <Dialog
          open={uploadDialogOpen}
          onClose={() => setUploadDialogOpen(false)}
          className={
            uploadingDialogOpen
              ? 'image-dialog image-dialog-upload'
              : 'image-dialog'
          }
        >
          <div style={styles.dialogContainer}>
            {uploadingDialogOpen ? (
              <DialogTitle
                className='flex flex-center'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontFamily: 'Inter',
                  fontSize: '18px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  letterSpacing: '0.03em',
                  color: '#000000',
                  paddingBottom: '33px',
                  paddingTop: '28px',
                }}
              >
                Uploading
              </DialogTitle>
            ) : (
              <DialogTitle
                className='flex flex-center'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontFamily: 'Inter',
                  fontSize: '18px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  letterSpacing: '0.03em',
                  color: '#000000',
                  paddingBottom: '37px',
                  paddingTop: '28px',
                }}
              >
                Upload
                <UploadOutlinedIcon style={{ marginLeft: '3px' }} />
              </DialogTitle>
            )}
            <DialogContent style={styles.dialogContent}>
              {!uploadingDialogOpen ? (
                <>
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      setUploadingDialogOpen(true);
                      handleImageChange(acceptedFiles);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section
                        className='flex flex-center image-picker'
                        {...getRootProps()}
                      >
                        <div>
                          <input
                            {...getInputProps({
                              accept: 'image/jpeg, image/png',
                              type: 'file',
                            })}
                          />
                          <p
                            style={{
                              fontFamily: 'Inter',
                              fontSize: '14px',
                              fontWeight: 500,
                              lineHeight: '24px',
                              letterSpacing: '0.03em',
                              textAlign: 'left',
                              padding: 0,
                              margin: 0,
                              marginBottom: '10px',
                              color: '#000000',
                            }}
                          >
                            Drop File here
                          </p>
                          <p
                            style={{
                              fontFamily: 'Inter',
                              fontSize: '14px',
                              fontWeight: 500,
                              lineHeight: '24px',
                              letterSpacing: '0.03em',
                              textAlign: 'left',
                              color: '#000000',
                              padding: 0,
                              margin: 0,
                              marginBottom: '20px',
                            }}
                          >
                            or
                          </p>
                          <button>Choose File</button>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </>
              ) : (
                <section className='flex flex-center image-uploader'>
                  <div>
                    <div className='image-uploader-inner'>
                      <div className='image-uploader-part-1'>
                        <span>{profileImage?.name}</span>
                        <TrashIcon
                          className='pointer'
                          onClick={() => {
                            handleImageCancel();
                          }}
                        />
                      </div>
                      {/* <div className="image-uploader-part-2">
                        Uploading 2KB of 10KB
                      </div> */}
                    </div>
                    <button onClick={handleImageUpload}>
                      Set as Profile Image
                    </button>
                  </div>
                </section>
              )}
              {/* {imagePreview && (
                <span style={styles.previewImg} className="flex flex-center">
                  <img
                    src={imagePreview}
                    style={{ width: "100px", height: "100px" }}
                    alt="Preview"
                  />
                  <Button
                    style={styles.deleteIcon}
                    onClick={() => setImagePreview(null)}
                  >
                    <DeleteForeverRoundedIcon />
                  </Button>
                </span>
              )} */}
            </DialogContent>
            {/* {imagePreview && (
              <DialogActions>
                <Button onClick={handleImageCancel}>Cancel</Button>
                <Button onClick={handleImageUpload}>Upload</Button>
              </DialogActions>
            )} */}
          </div>
        </Dialog>

        <div style={styles.rightContainer}>
          {windowSize > 500 ? (
            <p style={styles.title}>General Information</p>
          ) : (
            ''
          )}
          <TextField
            style={styles.textInput}
            id='name'
            label='Name'
            variant='outlined'
            size='small'
            value={userData?.name || ''}
          />
          <TextField
            style={styles.textInput}
            id='phone'
            label='Phone'
            variant='outlined'
            value={userData?.phone_number || ''}
            size='small'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>+91</InputAdornment>
              ),
            }}
          />
          <TextField
            style={styles.textInput}
            id='email'
            label='Email'
            variant='outlined'
            size='small'
            value={userData?.email || ''}
          />
          <TextField
            style={styles.textInput}
            id='city'
            label='City'
            variant='outlined'
            size='small'
            // value={userData?.user_address.city}
            value={modifiedUserData?.user_address.city || ''}
            onChange={(e) => handleInputChange('city', e.target.value)}
          />
          <div style={{ ...styles.textInput }}>
            <StateInputField
              // selectedState='AP'
              selectedState={modifiedUserData?.user_address.state || 'AP'}
              // onChange={(key) => {
              //   console.log('state changed', key);
              // }}
              onChange={(key) => handleInputChange('state', key)}
            />
          </div>

          <p style={styles.title}>Information for Invoices</p>
          <TextField
            style={styles.textInput}
            id='companyName'
            label='CompanyName'
            variant='outlined'
            size='small'
            value={modifiedUserData?.invoice_information.company_name}
            onChange={(e) =>
              handleInvoiceInputChange('company_name', e.target.value)
            }
          />
          <TextField
            style={styles.textInput}
            id='GstNo'
            label='GST No.'
            variant='outlined'
            size='small'
            value={modifiedUserData?.invoice_information.gst_number}
            onChange={(e) =>
              handleInvoiceInputChange('gst_number', e.target.value)
            }
          />
          <TextField
            style={styles.textInput}
            id='city'
            label='City'
            variant='outlined'
            size='small'
            value={modifiedUserData?.invoice_information.city}
            onChange={(e) => handleInvoiceInputChange('city', e.target.value)}
          />
          <TextField
            style={styles.textInput}
            id='pincode'
            label='Pincode'
            variant='outlined'
            size='small'
            value={modifiedUserData?.invoice_information.pin_code}
            onChange={(e) =>
              handleInvoiceInputChange('pin_code', e.target.value)
            }
          />
          <div style={{ ...styles.textInput }}>
            <StateInputField
              selectedState={
                modifiedUserData?.invoice_information.state || 'AP'
              }
              onChange={(key) => {
                handleInvoiceInputChange('state', key);
              }}
            />
          </div>

          <button style={styles.button} onClick={handleSaveButton}>
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  responsiveProfile: {
    backgroundColor: '#FFFFFF' as const,
  },
  textInput: {
    width: '40%',
    minWidth: '240px',
    margin: Theme.gapSmall,
  },
  button: {
    ...ThemeTypes.yellowButton,
    backgroundColor: Theme.colors.blueSolid,
    color: Theme.colors.white,
    margin: Theme.gapSmall,
    marginTop: '51px',
  },
  title: {
    fontSize: Theme.fontSizes.h3,
    color: Theme.colors.black,
    margin: 0,
    padding: Theme.gapSmall,
  },
  leftContainerResponsive: {
    width: Theme.profileImageWidth,
    height: Theme.profileImageWidth,
    margin: Theme.gapSmall,
    position: 'relative' as const,
  },
  leftContainer: {
    width: Theme.profileImageWidth,
    height: Theme.profileImageWidth,
    margin: Theme.gapLarge,
    marginTop: Theme.gapXXLarge,
    marginRight: Theme.gapSmall,
    position: 'relative' as const,
  },
  profileImageStyle: {
    width: '100%',
    height: '100%',
  },
  rightContainer: {
    flex: 1,
  },
  containerResponsive: {
    display: 'flex' as const,
    flexDirection: 'column' as const,
    justifyContent: 'space-between' as const,
    alignItems: 'flex-start' as const,
  },
  container: {
    display: 'flex' as const,
    flexDirection: 'row' as const,
    justifyContent: 'space-between' as const,
    alignItems: 'flex-start' as const,
  },
  memberSince: {
    fontSize: Theme.fontSizes.h4,
    color: Theme.colors.black70,
    padding: Theme.gapTiny,
    margin: 0,
    borderBottom: `1px solid ${Theme.colors.black70}`,
  },
  editIcon: {
    position: 'absolute' as const,
    top: 0,
    right: 0,
    cursor: 'pointer',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    borderRadius: '50%',
    padding: '0.12rem',
  },
  deleteIcon: {
    maxWidth: '20px !important',
    position: 'absolute' as const,
    top: '0',
    right: '25%',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    borderRadius: '20%',
    color: 'black',
    border: 'solid',
    cursor: 'pointer',
    padding: '0.1rem',
  },

  dialogContainer: {
    width: '100%',
  },

  previewImg: {
    // maxWidth: '100px',
    maxHeight: '100px',
    position: 'relative' as const,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column' as const,
    paddingBottom: '34px',
    alignItems: 'center',
    justifyContent: 'center',
  },

  Imagebutton: {
    border: '0',
    padding: '0.5rem 1.5rem',
    background: '#a4abb9',
    borderRadius: '2px',
    cursor: 'pointer',
  },
};

export default MyProfile;
