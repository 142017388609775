import React, { useEffect, useRef, useState } from "react";
import "./livetoday.css";
import EditIcon from "@mui/icons-material/Edit";
import PauseCircleOutlineOutlinedIcon from "@mui/icons-material/PauseCircleOutlineOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { Styles } from "../../Utils/Constants";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { formatNumber } from "../../API/FormatNumber";
import Loader from "../Dialogs/Loader";
import useWindowSize from "../Hooks/useWindowSize";
import { West } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  MinCapResponseData,
  PositionDetails,
  SubscriptionDetailResponseData,
  UserLiveTradeAPIInterface,
  editLots,
  fetchAllTradingPositionByStrategyAndUserId
} from "../../API/LiveTodayAPI";
import { decodeJwtToken } from "../../API/DecodeJWTFunction";
import { UpGradePlanMessagePopup } from "./UpGradePlanPopUp";
import { FetchBrokerDetailsAPI } from "../../API/ProfileAPI";
import useAppStore from "../../store/app.store";
import Toast from "../../Utils/Toast";
import { getAboutMe, getStrategyDetails, getSubscriptionDetails, LiveTodayKillAPI, subscriptionPauseResumeAPI } from "../../service/apiService";

var userId: string = "";

interface LtpData {
  [position: string]: {
    last_price: number;
  };
}

interface LTPDataCollection {
  [key: string]: LtpData[];
}

const notificationChannel = new BroadcastChannel('notification');

const LiveTodayPageContent = () => {
  const navigate = useNavigate();
  const windowSize = useWindowSize();

  const decodedToken = decodeJwtToken();

  userId = String(decodedToken?.user_id ?? "");

  const [isLoading, setIsLoading] = useState(false);
  const [isConnected, setIsConnecisConnected] = useState(true);
  const [subscriptionDetails, setSubscriptionDetails] = useState<
    SubscriptionDetailResponseData[]
  >([]);
  const [minCap, setMinCap] = useState<MinCapResponseData>({});
  const [strategyRowData, setStrategyRowData] = useState<{
    [key: string]: PositionDetails[];
  }>({});
  const [strategyStatus, setStrategyStatus] = useState<{
    [key: string]: boolean;
  }>({});
  const [showKillPopup, setShowKillPopup] = useState(false);
  const [showPauseResumePopup, setShowPauseResumePopup] = useState(false);
  const [ltpsData, setLTPSData] = useState<LtpData>({});
  const [stratID, setStratId] = useState("");
  const [lots, setLots] = useState(0);
  const [showEditLotsPopup, setShowEditLotsPopup] = useState(false);
  const [termsConditionAccepted, setTermsConditionAccepted] =
    useState<boolean>(true);
  const [strategyPlanDetails, setStrategyPlanDetails] = useState<any>({});
  const [backtestingPlanDetails, setBacktestingPlanDetails] = useState<any>({});
  const { setSelectedSectionIndex } = useAppStore();
  const newStrategyRowData: { [key: string]: PositionDetails[] } = {};
  const newStrategyStatus: { [key: string]: boolean } = {};
  //ws

  const ws = useRef<WebSocket | null>(null);
  interface StrategyPnlStructure {
    [key: string]: number;
  }

  const TotalStrategyPnl: StrategyPnlStructure[] = [];


  const APPSYNC_HOST = process.env.REACT_APP_APPSYNC_HOST as string;
  const APPSYNC_REALTIME_HOST = process.env.REACT_APP_APPSYNC_HOST_REALTIME as string;

  function encodeAppSyncCredentials() {
    const creds = {
      host: APPSYNC_HOST,
      "x-api-key": process.env.REACT_APP_APPSYNC_API_KEY as string,
    };
    const b64Creds = window.btoa(JSON.stringify(creds));
    return b64Creds;
  }

  const handleConnectBroker = () => {
    // Redirect to broker page when the button is clicked
    setSelectedSectionIndex(2);
    navigate('/broker', { replace: false });
  };

  function getWebsocketUrl() {
    const header = encodeAppSyncCredentials();
    const payload = window.btoa(JSON.stringify({}));
    const url = `wss://${APPSYNC_REALTIME_HOST}/graphql?header=${header}&payload=${payload}`;
    return url;
  }

  notificationChannel.onmessage = (event) => {
    const message = event.data;
    Toast.success(message.data);
  };

  const fetchSubsriptionDetailsData = async () => {
    try {
      const details = await getSubscriptionDetails(false);
      const responseDataArray = details.Response_data;
      // Filter out undefined or empty strategy IDs
      const filteredStrategyArray = responseDataArray.filter(
        (item) =>
          item.strategy_id.trim() !== "undefined" &&
          item.strategy_id.trim() !== ""
      );
      setSubscriptionDetails(filteredStrategyArray);
    } catch (error: any) {
      console.error(error.message);
    }
  };

  const getMinCapitalRequired = async (strategyId: string) => {
    const data = await getStrategyDetails(false,strategyId);
    const responseData = data.response_data;
    const minCap = responseData.minimum_investment_capital;
    return minCap;
  };

  const getAndSetMinCap = async () => {
    const promises = subscriptionDetails.map(async (data) => {
      const minCap = await getMinCapitalRequired(data?.strategy_id);
      return { [data?.strategy_id]: minCap };
    });
    const minCapArray = await Promise.all(promises);
    const minCapObject = minCapArray.reduce((acc, curr) => {
      return { ...acc, ...curr };
    }, {});
    setMinCap(minCapObject);
  };

  const getAndSetProperties = async () => {
    const promises = subscriptionDetails.map(async (data) => {
      const strategyData = await fetchDataForStrategy(
        data?.strategy_id,
        userId
      );
      return { strategy_id: data?.strategy_id, strategyData };
    });
    const responseDataForStrategy = await Promise.all(promises);

    responseDataForStrategy.forEach(({ strategy_id, strategyData }) => {
      newStrategyRowData[strategy_id] = strategyData;
      newStrategyStatus[strategy_id] = strategyData.some(
        (position) => position.Entry
      );
    });

    setStrategyRowData(newStrategyRowData);
    setStrategyStatus(newStrategyStatus);
  };

  const handleStrategyStatus = (data: PositionDetails[]) => {
    // return data.some((position) => position.Entry);
    newStrategyStatus['strat_0008'] = data.some((position) => position.Entry);
    setStrategyStatus(newStrategyStatus);
  };


  const handleCancelClick = () => {
    setShowEditLotsPopup(false);
    setTermsConditionAccepted(false);
  };

  const handleSaveClick = async () => {
    if (termsConditionAccepted) {
      if (lots > strategyPlanDetails.max_lots) {
        UpGradePlanMessagePopup(strategyPlanDetails.max_lots);
      } else {
        try {
          const apiresponse = await editLots(stratID, lots);
          //alert(apiresponse.Message);
          Toast.success(apiresponse.Message);
          // Show success toast
          // window.location.reload();
        } catch (error: any) {
          Toast.error(error.message);
        }
      }
      setShowEditLotsPopup(false);
      setTermsConditionAccepted(false);
      // window.location.reload();
    } else {
      alert("Accept the terms and condition");
    }
  };

  const handleRadioClick = () => {
    setTermsConditionAccepted((prevCondition) => !prevCondition);
  };

  const handleEditClick = (strategy_Id: string, prevLots: number) => {
    setLots(prevLots);
    setStratId(strategy_Id);
    setShowEditLotsPopup(true);
  };

  const handlePauseResumePopup = (strategy_Id: string) => {
    setStratId(strategy_Id);
    setShowPauseResumePopup(true);
  };

  const handlePauseResume = async () => {
    try {
      const result = await subscriptionPauseResumeAPI(false,stratID);
      setShowPauseResumePopup(false);
      window.location.reload();
    } catch (error: any) {
      console.error(error.message);
    }
  };

  const handleKill = (strategy_Id: string) => {
    setStratId(strategy_Id);
    setShowKillPopup(true);
  };

  const handleKillSubmit = async ({ strategyId }: { strategyId: string }) => {
    setShowKillPopup(false);
    try {
      const apiResponse = await LiveTodayKillAPI(false,userId, strategyId);
      if (apiResponse.message == undefined) {
        Toast.success("Internal Server Error"); // Show success toast
      } else {
        Toast.success(apiResponse.message); // Show success toast
      }
    } catch (error) {
      Toast.error("Failed to fetch data");
    }
  };

  const fetchDataForStrategy = async (
    strategyId: string,
    userId: string
  ): Promise<PositionDetails[]> => {
    try {
      const apiResponse: UserLiveTradeAPIInterface =
        await fetchAllTradingPositionByStrategyAndUserId(userId, strategyId);

      const responseDataArray: PositionDetails[] =
        apiResponse.response_data.PositionDetails || [];

      const filteredResponseDataArray = responseDataArray.filter(
        (item) => item.OrderStatus !== "REJECTED"
      );

      return filteredResponseDataArray;
    } catch (error) {
      console.error(`Error fetching data for strategyId ${strategyId}:`, error);
      return []; // Return empty array to handle error case
    }
  };

  const fetchUserData = async () => {
    const userData = await getAboutMe(true);
    const { strategy, backtesting } = userData.user_plan_details;
    setStrategyPlanDetails(strategy);
    setBacktestingPlanDetails(backtesting);
  };

  useEffect(() => {
    if (subscriptionDetails) {
      getAndSetMinCap();
      getAndSetProperties();
    }
  }, [subscriptionDetails]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchUserData();
      await fetchSubsriptionDetailsData();
    };
    fetchData();
  }, []);

  useEffect(() => {
    FetchBrokerDetailsAPI(userId).then((result) => {
      if (result.response_code === 200 && result.response_data.length > 0) {
        setIsConnecisConnected(true);
      } else {
        setIsConnecisConnected(false);
      }
    });
  }, []);

  const selectedStrategyId = stratID;
  const selectedStrategy = subscriptionDetails.find(
    (strategy) => strategy.strategy_id === selectedStrategyId
  );

  function subscribeMessage(query: string) {
    return {
      id: window.crypto.randomUUID(),
      type: "start",
      payload: {
        data: JSON.stringify({
          query: `${query}`,
        }),
        extensions: {
          authorization: {
            "x-api-key": process.env.REACT_APP_APPSYNC_API_KEY as string,
            host: APPSYNC_HOST,
          },
        },
      },
    };
  }


  async function getLiveLtp() {
    const header = encodeAppSyncCredentials();
    const payload = window.btoa(JSON.stringify({}));
    const url = `wss://${APPSYNC_REALTIME_HOST}/graphql?header=${header}&payload=${payload}`;

    try {
      ws.current = new WebSocket(url, ["graphql-ws"]);
      ws.current.onopen = () => {
        if (ws.current && ws.current.readyState === WebSocket.OPEN) {
          ws.current.send(JSON.stringify({ type: "connection_init" }));

          console.log("Web socket connected successfully");

          ws.current?.send(
            JSON.stringify(
              subscribeMessage(`
            subscription MySubscription {
              onCreateMoneyDevPositionLTP {
                LTP
                Position
              }
            }
            `)
            )
          );
          ws.current?.send(
            JSON.stringify(
              subscribeMessage(`
              subscription MySubscription {
                onUpdateMoneyDevPositionLTP {
                      LTP
                      Position
              }
      }
            `)
            )
          );

          ws.current?.send(
            JSON.stringify(
              subscribeMessage(`
              subscription MySubscription {
                onCreateMoneyUserOrderDetails(UserId: "${userId}") {
                  AveragePrice
                  AveragePriceType
                  DateTime
                  Direction
                  Entry
                  ExitAveragePrice
                  ExitReason
                  ExitSignalID
                  ExitSignalTime
                  LastUpdated
                  Lots
                  Ltp
                  OrderIDs
                  OrderStatus
                  Position
                  PositionID
                  Quantity
                  SignalID
                  Slices
                  TradingDate
                  UserId
                }
              }
              `)
            )
          );

          ws.current?.send(
            JSON.stringify(
              subscribeMessage(`
              subscription MySubscription {
                onUpdateMoneyUserOrderDetails(UserId: "${userId}") {
                  AveragePrice
                  AveragePriceType
                  DateTime
                  Direction
                  Entry
                  ExitAveragePrice
                  ExitReason
                  ExitSignalID
                  ExitSignalTime
                  LastUpdated
                  Lots
                  Ltp
                  OrderIDs
                  OrderStatus
                  Position
                  PositionID
                  Quantity
                  SignalID
                  Slices
                  TradingDate
                  UserId
                }
              }
              `)
            )
          );
        } else {
          console.log("WebSocket is not open or initialized properly");
        }
      };

      // ws.current.onmessage = (e) => {
      //   // console.log(JSON.parse(e.data));
      //   //here we are recieving data
      //   console.log("data ", e.data);
      //   const { onCreateMoneyDevPositionLTP } = e.data;
      //   if (onCreateMoneyDevPositionLTP) {
      //     const { Position, LTP } = onCreateMoneyDevPositionLTP;
      //     setLTPSData((prevLtpsData) => ({
      //       ...prevLtpsData,
      //       [Position]: {
      //         last_price: LTP
      //       }
      //     }));

      //   }
      // };

      ws.current.onmessage = (e) => {
        // // console.log(e.data);

        const { payload } = JSON.parse(e.data);
        if (payload && payload.data) {
          const { data } = payload;

          //  when in real time LTP data is getting updated
          if (
            data &&
            Object.keys(data).length === 1 &&
            data.onUpdateMoneyDevPositionLTP
          ) {
            const { Position, LTP } = data.onUpdateMoneyDevPositionLTP;
            // // console.log('Position', Position);

            setLTPSData((prevLtpsData) => {
              const updatedData = {
                ...prevLtpsData,
                [Position]: {
                  last_price: LTP,
                },
              };
              return updatedData;
            });
          }
          //  when in real time new positon is getting updated
          if (
            data &&
            Object.keys(data).length === 1 &&
            data.onCreateMoneyDevPositionLTP
          ) {
            const { Position, LTP } = data.onCreateMoneyDevPositionLTP;
            // console.log('Position', Position);

            setLTPSData((prevLtpsData) => {
              const updatedData = {
                ...prevLtpsData,
                [Position]: {
                  last_price: LTP,
                },
              };
              // console.log('Updated LTPS data: ', updatedData);
              return updatedData;
            });
          }

          if (
            data &&
            Object.keys(data).length === 1 &&
            data.onCreateMoneyUserOrderDetails
          ) {
            // console.log(
            //   'Positions data on create',
            //   data.onCreateMoneyUserOrderDetails
            // );
            const newDeatils = data.onCreateMoneyUserOrderDetails;

            const newData = {
              Position: newDeatils.Position,
              EntryAveragePrice: newDeatils.AveragePrice,
              Quantity: newDeatils.Quantity,
              ExitAveragePrice: newDeatils.ExitAveragePrice,
              Entry: newDeatils.Entry,
              PositionID: newDeatils.PositionID,
              Direction: newDeatils.Direction,
              PnL: null,
              OrderStaus: newDeatils.OrderStatus,
            } as unknown as PositionDetails;

            // const algoPositionKey = Object.keys(
            //   algoPositionsDataByStrategiesId
            // )[0];
            setStrategyRowData((prevData) => {
              const updatedData = {
                strat_0008: Array.isArray(prevData["strat_0008"])
                  ? [...prevData["strat_0008"], newData]
                  : [newData],
              };

              // console.log("upfated data", updatedData);
              // updateStrategyStatus(updatedData['strat_0008']);
              handleStrategyStatus(updatedData["strat_0008"]);
              return updatedData;
            });
          }

          if (
            data &&
            Object.keys(data).length === 1 &&
            data.onUpdateMoneyUserOrderDetails
          ) {
            // console.log(
            //   'Positions data on update',
            //   data.onUpdateMoneyUserOrderDetails
            // );

            const newDeatils = data.onUpdateMoneyUserOrderDetails;

            // const newData = {
            //   ExitAveragePrice: newDeatils.ExitAveragePrice,
            //   Entry: newDeatils.Entry,
            //   PositionID: newDeatils.PositionID,
            //   Direction: newDeatils.Direction,
            //   PnL: null,
            // } as unknown as PositionDetails;

            //        setalgoPositionsDataByStrategiesId(prevState => {
            //   const keyToUpdate = 'strat_0008'; // Example key to update

            //   // Map through the previous state and update only the matching item
            //   const updatedData = prevState[keyToUpdate].map(item => {
            //     if (item.PositionID === newData.PositionID) {
            //       return {
            //         ...item,
            //         ExitAveragePrice: newData.ExitAveragePrice,
            //         Entry: newData.Entry,
            //         Direction: newData.Direction,
            //         PnL: null,
            //       };
            //     }
            //     return item; // Return unchanged items
            //   });

            //   // Create the updated state object with the updated data and unchanged data
            //   const updatedState = {
            //     ...prevState, // Keep other keys and their data unchanged
            //     [keyToUpdate]: updatedData, // Update the specific key with the updated data
            //   };

            //   return updatedState;
            // });
            // Assuming algoPositionsDataByStrategiesId contains your current state data
            setStrategyRowData((prevData) => {
              const updatedData = prevData["strat_0008"].map((item) => {
                if (item.PositionID === newDeatils.PositionID) {
                  // Update the item with the new details
                  return {
                    ...item,
                    ExitAveragePrice: newDeatils.ExitAveragePrice,
                    Entry: newDeatils.Entry,
                    // Direction: newDeatils.Direction,
                    // Update other properties as needed
                  };
                }
                return item;
              });

              const updatedMoneyUserOrderDetails = {
                ["strat_0008"]: updatedData,
                // Update other strategy data if needed
              };

              // updateStrategyStatus(updatedMoneyUserOrderDetails['strat_0008']);
              console.log(
                "updatedMoneyUserOrderDetails",
                updatedMoneyUserOrderDetails
              );

              handleStrategyStatus(updatedMoneyUserOrderDetails["strat_0008"]);

              return updatedMoneyUserOrderDetails;
            });

            // console.log(
            //   'Positions data after update',
            //   algoPositionsDataByStrategiesId
            // );
          }
        }
      };

      ws.current.onerror = (e) => {
        console.log("Some error occured", e);
      };
      ws.current.onclose = (e) => {
        console.log(e.code, e.reason);
        // console.log("Socket is closed. Reconnect will be attempted in 1 second.");
        // setTimeout(() => {
        //   getLiveLtp();
        // }, 1000);
      };
    } catch (error) {
      console.log("An error has occurred");
    }
  }

  useEffect(() => {
    getLiveLtp();
  }, []);

  const getLtpForPosition = (positionValue: string): number | undefined => {
    const posValue = `${positionValue}`;

    if (ltpsData[posValue]) {
      return ltpsData[posValue]?.last_price;
    } else {
      // console.log(`Position ${posValue} not found in the data`);
      return undefined;
    }
  };

  const handlePnlPriceWhenPositonisOpen = (
    stratID: string,
    ltpPrice: number,
    entryPrice: number,
    direction: string,
    quantity: number
  ) => {
    let res = 0;
    if (direction === "BUY") {
      res = ltpPrice - entryPrice;
    } else if (direction === "SELL") {
      res = entryPrice - ltpPrice;
    }
    let _LegPnl;
    _LegPnl = res * quantity;
    TotalStrategyPnl.push({ [stratID]: _LegPnl });
    return _LegPnl;
  };

  const handlePnlPriceWhenPositonisClosed = (
    stratID: string,
    entryPrice: number,
    direction: string,
    quantity: number,
    exitPrice: number
  ) => {
    let res = 0;
    if (direction === "BUY") {
      res = exitPrice - entryPrice;
    } else if (direction === "SELL") {
      res = entryPrice - exitPrice;
    }
    let _LegPnl;
    _LegPnl = res * quantity;
    TotalStrategyPnl.push({ [stratID]: _LegPnl }); // Use the actual strategy ID
    return _LegPnl;
  };

  const TotalStrategyPnlbyStrategyID = (stratID: string) => {
    const totalSum: number = TotalStrategyPnl.reduce((acc, currentValue) => {
      if (currentValue.hasOwnProperty(stratID)) {
        acc += currentValue[stratID];
      }
      return acc;
    }, 0);
    return totalSum;
  };


  const TotalStrategyPnlPctbyStrategyID = (
    stratID: string,
    totalCapital: number
  ) => {
    const totalSum: number = TotalStrategyPnl.reduce((acc, currentValue) => {
      if (currentValue.hasOwnProperty(stratID)) {
        acc += currentValue[stratID];
      }
      return acc;
    }, 0);

    // Calculate the percentage of totalSum relative to totalCapital
    let pnlpct = ((totalSum / totalCapital) * 100).toFixed(2);
    return pnlpct;
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <div className="live-today-page">
        <div className="live-today-heading">
          {windowSize <= 800 && (
            <West
              onClick={() => {
                navigate("/");
              }}
              style={{ cursor: "pointer" }}
            />
          )}
          <span>Live Today</span>
        </div>
        {isConnected ? (
          <>
            {subscriptionDetails &&
              subscriptionDetails.map((subscriptionData, index) => {
                const todaysCapital =
                  subscriptionData.lots *
                  (Number(
                    String(minCap[subscriptionData?.strategy_id]).replace(/,/g, "")
                  ) || 0);
                return (
                  <span key={index}>
                    <div className="live-today-container">
                      <div className="live-today-container-header">
                        <div className="live-today-container-header-left-half">
                          <span>{subscriptionData?.strategy_name} </span> by Moneyy.ai
                        </div>
                        <div
                          className="live-today-container-header-right-half"
                          style={{ display: "flex" }}
                        >
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              minWidth: "75px",
                            }}
                            className="lots-container"
                          >
                            {subscriptionData?.lots} Lots
                            <div
                              onClick={() =>
                                handleEditClick(subscriptionData?.strategy_id, subscriptionData?.lots)
                              }
                            >
                              <EditIcon
                                style={{
                                  fontSize: "17px",
                                  paddingTop: "5px",
                                  marginLeft: "2px",
                                }}
                              />
                            </div>
                          </span>

                          {windowSize > 500 && (
                            <span
                              onClick={() =>
                                handlePauseResumePopup(subscriptionData?.strategy_id)
                              }
                              className="pause"
                              style={{ width: "23px", height: "23px" }}
                            >
                              {subscriptionData?.is_active ? (
                                <PauseCircleOutlineOutlinedIcon />
                              ) : (
                                <PlayCircleOutlineIcon />
                              )}
                            </span>
                          )}
                          <div
                            className="cancel"
                            onClick={() => handleKill(subscriptionData?.strategy_id)}
                          >
                            <CancelIcon
                              style={{
                                color: "red",
                                margin: "0 -3px 0 -5px",
                                padding: "0",
                              }}
                            />
                            Kill
                          </div>
                          {windowSize <= 500 && (
                            <span
                              onClick={() =>
                                handlePauseResumePopup(subscriptionData?.strategy_id)
                              }
                              className="pause"
                              style={{ width: "23px", height: "23px" }}
                            >
                              {subscriptionData?.is_active ? (
                                <PauseCircleOutlineOutlinedIcon />
                              ) : (
                                <PlayCircleOutlineIcon />
                              )}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="live-today-container-middle">
                        <div className="key-value-pair">
                          <span className="key">Today's Capital:</span>
                          <span className="value">
                            ₹
                            {formatNumber(
                              String(
                                subscriptionData.lots *
                                (Number(
                                  String(minCap[subscriptionData?.strategy_id]).replace(
                                    /,/g,
                                    ""
                                  )
                                ) || 0)
                              )
                            )}
                          </span>
                        </div>
                        <div className="key-value-pair">
                          <span className="key">Status: </span>
                          {strategyStatus[subscriptionData?.strategy_id] === false ? (
                            <span
                              className="value"
                              style={{ color: "#EF3333", fontWeight: "600" }}
                            >
                              Stopped{" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                viewBox="0 0 10 10"
                                fill="none"
                              >
                                <path
                                  d="M5 0C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10C7.76 10 10 7.76 10 5C10 2.24 7.76 0 5 0ZM5.5 7.5H4.5V4.5H5.5V7.5ZM5.5 3.5H4.5V2.5H5.5V3.5Z"
                                  fill="black"
                                  fillOpacity="0.4"
                                />
                              </svg>
                            </span>
                          ) : (
                            <span
                              className="value"
                              style={{ color: "#2ACD1C", fontWeight: "600" }}
                            >
                              Active{" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                viewBox="0 0 10 10"
                                fill="none"
                              >
                                <path
                                  d="M5 0C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10C7.76 10 10 7.76 10 5C10 2.24 7.76 0 5 0ZM5.5 7.5H4.5V4.5H5.5V7.5ZM5.5 3.5H4.5V2.5H5.5V3.5Z"
                                  fill="black"
                                  fillOpacity="0.4"
                                />
                              </svg>
                            </span>
                          )}
                        </div>
                        <div className="key-value-pair">
                          <span className="key">Broker:</span>
                          <span className="value">Kite</span>
                        </div>
                      </div>
                      <>
                        <div className="live-today-table">
                          {windowSize <= 800 && (
                            <>
                              {/* <div className="live-today-table-mobile-heading">
                                Positions
                              </div> */}

                                <div className="table-wrapper">

                              <table>
                                <thead>
                                  <tr>
                                    <th style={{ width: "26%" }}>Positions</th>
                                    <th style={{ width: "10%" }}>Qty.</th>
                                    <th style={{ width: "19%" }}>
                                      Entry Price ₹
                                    </th>
                                    <th style={{ width: "19%" }}>Exit Price ₹</th>
                                    <th style={{ width: "15%" }}>LTP ₹</th>
                                    <th style={{ width: "20%" }}>P&L ₹</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {strategyRowData[subscriptionData?.strategy_id] &&
                                    strategyRowData[subscriptionData?.strategy_id].map(
                                      (data, index) => {
                                        let LegPnlprice = 0;
                                        let lastPrice;
                                        if (data.Entry) {
                                          const positionValue = data.Position;

                                          lastPrice =
                                            getLtpForPosition(positionValue);

                                          LegPnlprice =
                                            handlePnlPriceWhenPositonisOpen(
                                              subscriptionData.strategy_id,
                                              lastPrice || 0,
                                              Number(data.EntryAveragePrice),
                                              data.Direction,
                                              Number(data.Quantity)
                                            );
                                          // pnlprice = 0
                                        } else {
                                          // pnlprice = Number(rowDataArray.PnL);
                                          LegPnlprice =
                                            handlePnlPriceWhenPositonisClosed(
                                              subscriptionData.strategy_id,
                                              Number(data.EntryAveragePrice),
                                              data.Direction,
                                              Number(data.Quantity),
                                              Number(data.ExitAveragePrice) || 0
                                            );
                                        }

                                        return (
                                          <tr key={index}>
                                            <td>{data?.Position}</td>
                                            {data?.Entry ? (
                                              <td
                                                style={{
                                                  color:
                                                    data.Direction.toLowerCase() ===
                                                      "sell"
                                                      ? "red"
                                                      : "green",
                                                }}
                                              >
                                                {data.Direction.toLowerCase() ===
                                                  "sell"
                                                  ? "-"
                                                  : ""}
                                                {data.Quantity}
                                              </td>
                                            ) : (
                                              <td>0</td>
                                            )}

                                            <td>{Number(
                                              data?.EntryAveragePrice
                                            ).toFixed(2)}</td>
                                            <td>
                                              {data &&
                                                (!data.Entry
                                                  ? Number(
                                                    data?.ExitAveragePrice
                                                  ).toFixed(2)
                                                  : "0.00")}
                                            </td>

                                            <td>
                                              {!data.Entry
                                                ? 0
                                                : lastPrice !== undefined
                                                  ? lastPrice
                                                  : 0}
                                            </td>
                                            <td
                                              style={{
                                                color:
                                                  LegPnlprice && LegPnlprice > 0
                                                    ? "#2ACD1C"
                                                    : "#EF3333",
                                              }}
                                            >
                                              {LegPnlprice.toFixed(2)}{" "}
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                </tbody>
                              </table>
                                </div>
                              {/* {strategyRowData[subscriptionData?.strategy_id] &&
                                strategyRowData[subscriptionData?.strategy_id].map(
                                  (subscriptionData, index) => {
                                    return (
                                      <div
                                        className="live-today-table-mobile-data"
                                        key={index}
                                      >
                                        {index + 1}. &nbsp; {subscriptionData?.Position}
                                      </div>
                                    );
                                  }
                                )} */}
                            </>
                          )}

                          {windowSize > 800 && (
                            <table>
                              <thead>
                                <tr>
                                  <th style={{ width: "26%" }}>Positions</th>
                                  <th style={{ width: "10%" }}>Qty.</th>
                                  <th style={{ width: "19%" }}>
                                    Entry Price ₹
                                  </th>
                                  <th style={{ width: "19%" }}>Exit Price ₹</th>
                                  <th style={{ width: "15%" }}>LTP ₹</th>
                                  <th style={{ width: "20%" }}>P&L ₹</th>
                                </tr>
                              </thead>
                              <tbody>
                                {strategyRowData[subscriptionData?.strategy_id] &&
                                  strategyRowData[subscriptionData?.strategy_id].map(
                                    (data, index) => {
                                      let LegPnlprice = 0;
                                      let lastPrice;
                                      if (data.Entry) {
                                        const positionValue = data.Position;

                                        lastPrice =
                                          getLtpForPosition(positionValue);

                                        LegPnlprice =
                                          handlePnlPriceWhenPositonisOpen(
                                            subscriptionData.strategy_id,
                                            lastPrice || 0,
                                            Number(data.EntryAveragePrice),
                                            data.Direction,
                                            Number(data.Quantity)
                                          );
                                        // pnlprice = 0
                                      } else {
                                        // pnlprice = Number(rowDataArray.PnL);
                                        LegPnlprice =
                                          handlePnlPriceWhenPositonisClosed(
                                            subscriptionData.strategy_id,
                                            Number(data.EntryAveragePrice),
                                            data.Direction,
                                            Number(data.Quantity),
                                            Number(data.ExitAveragePrice) || 0
                                          );
                                      }

                                      return (
                                        <tr key={index}>
                                          <td>{data?.Position}</td>
                                          {data?.Entry ? (
                                            <td
                                              style={{
                                                color:
                                                  data.Direction.toLowerCase() ===
                                                    "sell"
                                                    ? "red"
                                                    : "green",
                                              }}
                                            >
                                              {data.Direction.toLowerCase() ===
                                                "sell"
                                                ? "-"
                                                : ""}
                                              {data.Quantity}
                                            </td>
                                          ) : (
                                            <td>0</td>
                                          )}

                                          <td>{Number(
                                            data?.EntryAveragePrice
                                          ).toFixed(2)}</td>
                                          <td>
                                            {data &&
                                              (!data.Entry
                                                ? Number(
                                                  data?.ExitAveragePrice
                                                ).toFixed(2)
                                                : "0.00")}
                                          </td>

                                          <td>
                                            {!data.Entry
                                              ? 0
                                              : lastPrice !== undefined
                                                ? lastPrice
                                                : 0}
                                          </td>
                                          <td
                                            style={{
                                              color:
                                                LegPnlprice && LegPnlprice > 0
                                                  ? "#2ACD1C"
                                                  : "#EF3333",
                                            }}
                                          >
                                            {LegPnlprice.toFixed(2)}{" "}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                              </tbody>
                            </table>
                          )}
                          <div className="live-today-table-total">
                            <span>Total</span>
                            <span
                              style={{
                                color:
                                  TotalStrategyPnlbyStrategyID(
                                    subscriptionData?.strategy_id
                                  ) < 0
                                    ? "#EF3333"
                                    : "#2ACD1C",
                                marginRight: "4%",
                              }}
                            >
                              {TotalStrategyPnlbyStrategyID(
                                subscriptionData?.strategy_id
                              ).toFixed(2)}{" "}
                              (
                              {TotalStrategyPnlPctbyStrategyID(
                                subscriptionData?.strategy_id,
                                todaysCapital
                              )}
                              %)
                            </span>
                          </div>
                        </div>
                      </>
                    </div>

                    {showKillPopup && selectedStrategy && (
                      <>
                        <div className="kill-popup-card flex-center flex-col ">
                          <span style={Styles.h3Text}>
                            {selectedStrategy.strategy_name}
                          </span>
                          <p>
                            Please Confirm to kill{" "}
                            {selectedStrategy.strategy_name}
                          </p>
                          <div
                            className="flex-center"
                            style={{ width: "100%" }}
                          >
                            <button
                              className="flex-center"
                              onClick={() =>
                                handleKillSubmit({
                                  strategyId: selectedStrategy.strategy_id,
                                })
                              }
                            >
                              <DoneIcon />
                              Confirm
                            </button>
                            <button
                              style={{
                                color: "#2747dd",
                                backgroundColor: "#fff",
                                border: "solid",
                              }}
                              className="flex-center "
                              onClick={() => setShowKillPopup(false)}
                            >
                              <CloseIcon />
                              Cancel
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                    {showPauseResumePopup && selectedStrategy && (
                      <>
                        <div className="kill-popup-card flex-center flex-col">
                          <span style={Styles.h3Text}>
                            {selectedStrategy.strategy_name}
                          </span>
                          <p>
                            {selectedStrategy.strategy_name} will be{" "}
                            {!selectedStrategy.is_active
                              ? "Subscribed"
                              : "Unsubscribed"}{" "}
                            from tomorrow
                            {/* *{data.strategy_name} will be resumed from tomorrow */}
                          </p>
                          <div
                            className=' flex flex-center popup-card-button-row'
                            style={{ width: '100%' }}
                          >
                            <button
                              className="flex-center"
                              onClick={() => handlePauseResume()}
                            >
                              <DoneIcon />
                              Confirm
                            </button>
                            <button
                              style={{
                                color: "#2747dd",
                                backgroundColor: "#fff",
                                border: "solid",
                              }}
                              className="flex-center "
                              onClick={() => setShowPauseResumePopup(false)}
                            >
                              <CloseIcon />
                              Cancel
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                    {showEditLotsPopup && (
                      <div className="subscribe-popup-card">
                        <div className="popup-card-header">
                          <article>Choose the Lots Multiplier</article>
                          <br />

                          <div className="popup-card-row">
                            <article>Total Lots</article>
                            {/* <input type='text' placeholder='Enter Lots' /> */}
                            <input
                              type="number"
                              id="lots"
                              name="lots"
                              value={lots}
                              placeholder="Enter Lots"
                              onChange={(e) =>
                                setLots(parseInt(e.target.value, 10))
                              }
                            />
                          </div>
                          <p
                            style={{
                              fontSize: "14.5px",
                              color: "#000000B2",
                              fontWeight: "400",
                            }}
                          >
                            ( 1 Lot is the minimum capital required = ₹{" "}
                            {minCap[stratID]} )
                          </p>

                          <div className="popup-card-terms-conditions flex-center">
                            <div
                              style={{
                                display: "flex",
                                width: "15px",
                                height: "15px",
                                borderRadius: "50%",
                                border: "0.7px solid #333",
                                backgroundColor: termsConditionAccepted
                                  ? "#2747DD"
                                  : "transparent",
                                cursor: "pointer",
                                textAlign: "center",
                                lineHeight: "20px",
                                color: termsConditionAccepted ? "#fff" : "#333",
                                marginTop: "-14px",
                              }}
                              className="flex flex-center"
                              onClick={handleRadioClick}
                            >
                              {termsConditionAccepted && <span>&#10003;</span>}{" "}
                            </div>{" "}
                            {/* <input
                              style={{ border: 'solid', marginTop: '-7px' }}
                              type='radio'
                              id='radioButton'
                              name='radioGroup'
                              value='option56'
                              checked={termsConditionAccepted}
                              onClick={handleRadioClick}
                            /> */}
                            <span>
                              By Clicking on Subscribe button, you agree to our
                              <a href="/terms-and-conditions">
                                {" "}
                                Terms and Conditions{" "}
                              </a>
                              and{" "}
                              <a href="/privacy-policies"> Privacy Statement</a>
                              .
                            </span>
                          </div>

                          <br />

                          <div className="popup-card-button-row">
                            {/*  */}

                            {termsConditionAccepted ? (
                              <button
                                className="popup-subscribe-btn"
                                onClick={() => handleSaveClick()}
                              >
                                <DoneIcon
                                  style={{ width: "16px", height: "36px" }}
                                />{" "}
                                Subscribe
                              </button>
                            ) : (
                              <button
                                className="popup-subscribe-btn"
                                // onClick={() => handleSaveClick()}
                                disabled
                              >
                                <DoneIcon
                                  style={{ width: "16px", height: "36px" }}
                                />{" "}
                                Subscribe
                              </button>
                            )}
                            <button
                              className="popup-close-btn"
                              onClick={handleCancelClick}
                            >
                              <CloseIcon
                                style={{ width: "16px", height: "36px" }}
                              />
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </span>
                );
              })}
          </>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <div style={{ color: 'black' }}>
              Connect your preferred broker to run AI strategies seamlessly.
            </div>
            <br />
            <button
              style={{ marginBottom: '20px' }}
              onClick={handleConnectBroker}
            >
              Connect Broker
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default LiveTodayPageContent;
